/* You can add global styles to this file, and also import other style files */
.reportscontainer{

                    width: 96vw; height: 100vh;
                   
                   };
.reportscontainer1{

                    width:100vw; height:100vw !important;
                   
                   }