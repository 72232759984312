.screen-background{
  background: rgba(21, 18, 18, 0.451);
  height: 100vh;
  width: 100vw;
}

.seciton-left {
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: var(--signup2-section-left-bg-img);
  background-size: cover;

  .section-left-content {
    // max-width: 380px;
    max-width: 700px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    // background: rgba($color: #000000, $alpha: 0.65);
    z-index: -1;
  }
}

.form-holder {
  background: white;
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.signup-form {
  padding: 20px 20px 70px 20px;
}