.topnav {
	&:after {
		content: "";
		display: table;
		clear: both;
	}

	label.menu-toggle {
		height: 48px;
		width: 48px;
		box-sizing: border-box;
		padding: 12px;
		border-radius: 50%;

		.mat-icon {
			font-size: 24px;
		}
	}

	.toggle,
	[id^=drop] {
		display: none;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	ul:not(.menu) {
		box-shadow: 0 0 4px rgba(0, 0, 0, 0), 0 4px 8px rgba(0, 0, 0, .28);
	}

	ul.menu {
		float: left;
		height: 48px;
		padding-right: 45px;
		display: flex;
		/* Ensure items stay in a row */
		align-items: center;
		/* Align items vertically center */
		justify-content: flex-start;
		/* Start items from left */

		>li {
			float: left;
			display: inline-block;
			/* Ensure li items stay in a row */
			margin-right: 6px;

			/* Adjust margin between items */
			>div {

				>a,
				>div {
					border-bottom: 2px solid;
					height: 48px;
					box-sizing: border-box;
					border-color: transparent;
					margin: 0 6px;
				}
			}
		}
	}

	.navbar-divider {
		flex: 1;
		/* Takes up remaining space */
		display: flex;
		justify-content: flex-end;
		/* Align to the right */
		align-items: center;
		/* Center items vertically */
	}

	a,
	label {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 13px 20px;
		height: 44px;
		font-size: .875rem;
		text-decoration: none;
		box-sizing: border-box;

		.mat-icon {
			font-size: 16px;
			height: 20px;
			line-height: 20px;
			width: 20px;
			margin-right: 2px;
		}
	}

	ul li ul li:hover,
	ul li ul li.open {
		background: #eeeeee;
	}

	ul ul {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		background: #ffffff;
		color: rgba(0, 0, 0, .87);
		/* has to be the same number as the "line-height" of "nav a" */
		top: 48px;
		transform: translateY(-100px);
		transition: all 0.3s ease-in-out;
		z-index: -1;
	}

	ul li:hover>div>div>ul,
	ul li:hover>div>ul {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}

	ul ul li {
		width: 150px;
		float: none;
		display: list-item;
		position: relative;
	}

	ul ul ul {
		top: 0;
		left: 150px;
	}

	ul ul ul li {
		position: relative;
		top: 0;
	}

	li>a:after {
		content: ' +';
	}

	li>a:only-child:after {
		content: '';
	}
}

/* Media Queries */
@media all and (max-width : 768px) {
	.topnav {
		margin: 0;

		.toggle+a {
			display: none;
		}

		.menu {
			opacity: 0;
			visibility: hidden;
			height: auto !important;
			width: 100%;

			li {
				a {
					border: none !important;
				}
			}
		}

		.toggle {
			display: flex;
			text-decoration: none;
			border: none;
		}

		.toggle:hover {
			// background-color: #eeeeee;
		}

		ul {
			overflow: hidden;
			max-height: 0px;
			transform: translateY(0px) !important;
			transition: max-height 0.3s ease-in-out;
		}

		[id^=drop]:checked+ul {
			opacity: 1;
			visibility: visible;
			max-height: 2000px;
		}

		[id^=drop]:checked+ul.menu {
			max-height: 300px;
			overflow-y: scroll;
		}

		ul li {
			position: relative;
			opacity: 1;
			visibility: visible;
			width: 100%;
			z-index: 1;
		}

		ul ul .toggle,
		ul ul a {
			padding: 0 40px;
		}

		ul ul ul a {
			padding: 0 80px;
		}

		ul li ul li .toggle,
		ul ul a,
		ul ul ul a {
			padding: 14px 20px;
		}

		/* Hide Dropdowns by Default */
		ul ul {
			float: none;
			position: relative;
			top: 0;
			left: 0;
			box-shadow: none !important;
			z-index: 1;
		}

		/* Hide menus on hover */
		ul li:hover>div>div>ul,
		ul li:hover>div>ul {
			opacity: 0;
			visibility: hidden;
		}

		ul ul li {
			opacity: 1;
			visibility: visible;
			width: 100%;
		}

		ul ul ul {
			left: 0;
		}

		ul ul ul li {
			position: static;
		}
	}
}

@media all and (max-width : 330px) {
	.topnav ul li {
		display: block;
		width: 94%;
	}
}

[dir=rtl] {
	.topnav {

		a,
		label {
			.mat-icon {
				margin-right: 0;
				margin-left: 2px;
			}
		}
	}
}

.nested-menu-items {
	padding: 0;
	width: 100%;
	margin: 0;
}

.custom-navbar-lv3:hover {
	background-color: #335596 !important;
	color: white;
}

.custom-navbar-lv3 {
	padding: 0;
	width: 300px !important;
	height: 50px;
	margin: 0;
	white-space: nowrap;
	/* Prevent text from wrapping */
	text-overflow: ellipsis;
	/* Add ellipsis to indicate overflow */

	&::-webkit-scrollbar {
		width: 16px;
		height: 16px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #888;
		border-radius: 10px;
		border: 4px solid transparent;
		background-clip: content-box;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 10px;
	}
}

@media (max-width: 1800px) {
	.custom-navbar-lv3 {
		width: 300px !important;
		background-color: #ffffff;
	}

	.background-lv3 {
		background-color: #ffffff;
		height: 70vh !important;
		overflow-x: hidden;
		overflow-y: scroll;
	}
}

.navbar-divider {
	/* Keep it empty for now */
}

/* Navbar : Begin */
.mat-toolbar {
    background-color: #fff;
    color: white;
    position: relative;
    z-index: 9999;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  
  .logo-container {
    background-color: #fff;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .logo-container img {
    height: 40px !important;
  }
  
  .mat-toolbar-row {
    display: flex;
    align-items: center;
    margin: 10px 10px 10px 10px;
  }
  
  .menu-item {
    position: relative;
    padding: 10px;
    padding-bottom: 0px;
    cursor: pointer;
    color: #0d3c55 !important;
    border: none !important;
    background-color: #fff !important;
  }
  
  .menu-item:hover::after,
  .menu-item.active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #74c5b5; /* Hover color */
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .menu-item.active::after {
    background-color: #74c5b5; /* Active color */
  }
  
  .mat-menu-item {
    background-color: #335596;
  }
  
  .menu-item {
    cursor: pointer;
    margin-right: 20px;
    font-size: 18px;
    font-weight: normal;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  
  .mat-menu {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .mat-menu-item {
    display: flex;
    align-items: center;
  }
  
  .login-button {
    background-color: #74c5b5;
    border-radius: 7px;
    padding: 5px;
    color: white;
    border: none;
  }
  
  .login-button:hover {
    background-color: #4f8a7e;
  }
  
  .rightside-content-hold {
    padding-top: 0;
  }
  
  .example-spacer {
    flex: 1 1 auto;
  }
  /* DROPDOWN */
  .dropdown {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 100%; /* Position dropdown content below the button */
    left: 0;
    width: 100%; /* Full width of dropdown */
  
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
  }
  
  .dropdown-content a:hover {
    background-color: #74c5b5;
    color: white;
    font-weight: 700;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  /* Navbar : End */
  