$mat-primary: map-get($material-colors, purple);
$mat-accent: map-get($material-colors, amber);

$egret-primary: mat-palette($mat-primary);
$egret-accent: mat-palette($mat-accent);

// custom theme palettes are passed into a theme function
$egret-theme: mat-dark-theme($egret-primary, $egret-accent);

// Insert custom background color
$background: map-get($egret-theme, background);
$background: map_merge(
  $background,
  (
    background: #1a2038,
    card: #222a45,
    raised-button: #222a45,
    dialog: #222a45
  )
);
// @debug $background;


// (status-bar: black,
// app-bar: #212121,
// background: #000,
// hover: rgba(255, 255, 255, 0.04),
// card: #424242, dialog: #424242,
// disabled-button: rgba(255, 255, 255, 0.12),
// raised-button: #424242,
// focused-button: rgba(255, 255, 255, 0.12),
// selected-button: #212121,
// selected-disabled-button: #424242,
// disabled-button-toggle: black,
// unselected-chip: #616161,
// disabled-list-option: black)

$egret-theme: map_merge(
  $egret-theme,
  (
    background: $background
  )
);

$primary-dark: darken(map-get($mat-primary, 500), 8%);

// Material theme is wrapped by a parent class
.egret-dark-purple {
  @include angular-material-theme($egret-theme);

  .mat-bg-primary,
  .topbar,
  .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
  .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
  .fileupload-drop-zone.dz-file-over,
  .toolbar-avatar.online > .status-dot,
  .cal-open-day-events,
  div.hopscotch-bubble {
    background: map-get($mat-primary, 500) !important;
    color: #ffffff !important;
  }
  .mat-color-primary,
  .list-item-active,
  .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
  .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
  .sidenav-hold .sidenav li.open > a > span {
    color: map-get($mat-primary, 500) !important;
  }
  .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > a > span,
  .sidebar-panel .sidebar-list-item.open .sub-menu .mat-list-item.selected a {
    font-weight: 500;
  }
  .topnav ul.menu > li > div.open > a,
  .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
  .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
  .list-item-active {
    border-color: map-get($mat-primary, 500) !important;
  }
  .ngx-datatable.material,
  .messages-wrap .inbox-hold {
    background: transparent !important;
  }

  .header-topnav,
  .topnav ul ul,
  .app-user .app-user-name,
  .sidebar-panel .sidebar-list-item mat-icon:not(.menu-caret),
  .ngx-datatable.material *,
  .ql-snow .ql-picker,
  .mat-option,
  .mat-dialog-container,
  .ngx-pagination a,
  .ngx-pagination button {
    color: white !important;
  }
  .text-muted {
    color: rgba(255, 255, 255, 0.54) !important;
  }

  .ql-snow .ql-stroke {
    stroke: #ffffff;
  }
  .ql-snow .ql-fill {
    fill: #ffffff;
  }
  .ql-toolbar.ql-snow,
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: rgba(255, 255, 255, 0.12) !important;
  }

  // .sidebar-panel .navigation-hold,
  .message-item.open .message-head,
  .ngx-datatable.material .datatable-header,
  .bg-white,
  .cal-month-view .cal-cell-row:hover,
  .chats-wrap .conversations-hold .single-conversation.sender .conversation-msg,
  .cal-day-view .cal-hour:nth-child(odd),
  &.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu,
  .ngx-pagination a:hover,
  .ngx-pagination button:hover,
  .product-container .products-pagination .ngx-pagination .current {
    background: rgb(34, 42, 69) !important;
  }
  .sidebar-compact ul.submenu,
  .topnav ul li ul li:hover,
  .topnav ul li ul li.open,
  .default-bg,
  .default-light-bg {
    background: rgb(34, 42, 69);
    color: #ffffff;
  }
  .header-topnav,
  .topnav ul ul,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection)
    .datatable-body-row:hover
    .datatable-row-group,
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open,
  .cal-week-view .cal-day-headers .cal-header:hover,
  .cal-week-view .cal-day-headers .cal-drag-over,
  .cal-day-view .cal-hour-segment:hover,
  .cal-day-view .cal-drag-over .cal-hour-segment {
    background: rgb(34, 42, 69) !important;
  }
  .cal-month-view .cal-day-cell:not(:last-child),
  .cal-month-view .cal-days,
  .cal-week-view .cal-day-headers .cal-header:not(:last-child),
  .cal-week-view .cal-day-headers,
  .cal-month-view .cal-days .cal-cell-row,
  .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-color: #000 !important;
  }

  /* Hopscotch Tour */
  div.hopscotch-bubble {
    border-color: $primary-dark;
  }
  /* up arrow  */
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.up
    .hopscotch-bubble-arrow-border {
    border-bottom: 17px solid $primary-dark;
  }
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.up
    .hopscotch-bubble-arrow {
    border-bottom: 17px solid map-get($mat-primary, 500);
    top: -16px;
  }
  /* right arrow */
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.right
    .hopscotch-bubble-arrow-border {
    border-left: 17px solid $primary-dark;
  }
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.right
    .hopscotch-bubble-arrow {
    border-left: 17px solid map-get($mat-primary, 500);
    left: -1px;
  }
  /* bottom arrow */
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.down
    .hopscotch-bubble-arrow-border {
    border-top: 17px solid $primary-dark;
  }
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.down
    .hopscotch-bubble-arrow {
    border-top: 17px solid map-get($mat-primary, 500);
    top: -18px;
  }
  /* Left Arrow */
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.left
    .hopscotch-bubble-arrow-border {
    border-right: 17px solid $primary-dark;
  }
  div.hopscotch-bubble
    .hopscotch-bubble-arrow-container.left
    .hopscotch-bubble-arrow {
    left: 1px;
    border-right: 17px solid map-get($mat-primary, 500);
  }

  .mat-bg-accent,
  div.hopscotch-bubble .hopscotch-bubble-number,
  div.hopscotch-bubble .hopscotch-nav-button.next:hover,
  div.hopscotch-bubble .hopscotch-nav-button.next {
    background-color: map-get($mat-accent, 500);
    color: black;
  }
  .mat-bg-warn {
    background-color: #f44336;
    color: white;
  }
  .mat-color-accent {
    color: map-get($mat-accent, 500);
  }
  .mat-color-warn {
    color: #f44336;
  }
  .mat-color-default {
    color: #ffffff;
  }

  .fill-color-accent {
    color: map-get($mat-accent, 500);
  }

  .fill-color-warn {
    color: #f44336;
  }

  @media (max-width: 959px) {
    .default-bg-mobile {
      background: #424242;
      color: #ffffff;
    }
  }
}
