@each $colorName, $matColor in $material-colors {
  $color500: map-get($matColor, 500);
  $ihm-white500: map-get(map-get($matColor, contrast), 500);
  $foreground500: map-get(map-get($matColor, contrast), 500);
  .#{''+$colorName+''} {
    background-color: $color500 !important;

    // Material CDK Table
    .mat-table {
      background: transparent !important;
    }
    .ngx-datatable.material *,
    .mat-table,
    .mat-cell,
    .mat-header-cell {
      color: $foreground500 !important;
    }
    .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
    .ngx-datatable.material:not(.cell-selection)
      .datatable-body-row:hover
      .datatable-row-group {
      background: $color500, 5;
    }
  }
  // Text color
  .text-#{''+$colorName+''} {
    color: $color500;
  }
  // Sidebar backgrounds
  .sidebar-#{''+$colorName+''} {
    .navigation-hold {
      background: rgba($color500, .96) !important;

      a,
      .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
      .sidenav li.open > div > a > span:not(.menuitem-badge),
      .sidenav li.open > a > span,
      .icon-menu .mat-raised-button,
      .app-user .app-user-name,
      .branding .app-logo-text {
        color: $foreground500 !important;
      }

      // Change app-logo text to theme-primary color
      .branding .app-logo-text {
        color: $color500 !important;
      }
      
      a:hover {
        background: rgba(darken($color500, 20), .2) !important;
      }
      .app-user .app-user-photo,
      .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
        border-color: $foreground500 !important;
      }
      .icon-menu .mat-raised-button,
      .branding {
        //background: rgba($ihm-white500, 1) !important;
      }
      .app-user {
        // background: darken($color500, 5) !important;
      }
      li.open {
        // background: rgba($color500, .3) !important;
        > a {
          background: rgba(darken($color500, 20), .2) !important;
        }
      }
      .text-muted {
        color: desaturate($foreground500, 40) !important;
      }
    }
  }
  // Header backgrounds
  .topbar-#{''+$colorName+''} {
    .topbar.mat-toolbar {
      background: $color500 !important;
      color: $foreground500 !important;
    }
  }

  // Footer background
  .footer-#{''+$colorName+''} {
    .main-footer {
      background: $color500 !important;
      color: $foreground500 !important;
    }
  }
  

  // Generate classes for each color shades
  $contrastMap: map-get($matColor, contrast);
  @each $shade, $value in $matColor {
    @if $shade == contrast {
      

    } @else {
      .#{''+$colorName+''}-#{$shade} {
        background-color: $value !important;
      }
      // Foreground text color
      .#{''+$colorName+''}-#{$shade}-fg {
        color: map-get($contrastMap, $shade) !important;
      }

      // Text color
      .text-#{''+$colorName+''}-#{$shade} {
        color: $value !important;
      }
    }

  }

}
