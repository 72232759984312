$spaces: (
  0: 0px,
  4: 4px,
  8: 8px,
  12: 12px,
  16: 16px,
  24: 24px,
  28: 28px,
  32: 32px,
  36: 36px,
  40: 40px,
  44: 44px,
  48: 48px
);

@each $name, $value in $spaces {
  .m-#{$name} {
      margin: $value !important;
  }
  .mt-#{$name} {
    margin-top: $value !important;
  }
  .mr-#{$name} {
    margin-right: $value !important;
  }
  .mb-#{$name} {
    margin-bottom: $value !important;
  }
  .ml-#{$name} {
    margin-left: $value !important;
  }
  .mx-#{$name} {
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .my-#{$name} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  .p-#{$name} {
    padding: $value !important;
  }
  .pt-#{$name} {
    padding-top: $value !important;
  }
  .pr-#{$name} {
    padding-right: $value !important;
  }
  .pb-#{$name} {
    padding-bottom: $value !important;
  }
  .pl-#{$name} {
    padding-left: $value !important;
  }
  .px-#{$name} {
    padding-left: $value !important;
    padding-right: $value !important;
  }
  .py-#{$name} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
}

.m-auto {
  margin: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

// Old Spacing classes
.m-0 {
  margin: 0px !important;
}
.m-1 {
  margin: 1rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-05 {
  margin-top: .5rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-7{
  margin-top: 7px !important;
}
.mt-8{
  margin-top: 8px !important;
}
.mt-5{
  margin-top: 5px !important;
}
.mt-10{
  margin-top: 10px !important;
} 
.mt-2{
  margin-top: 2px !important;
}
.mt-15{
  margin-top: 15px;
}
.mt-4-per{
  margin-top: 4%;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-05 {
  margin-right: .5rem !important;
}
.mr-23{
  margin-right: 23px;
}
.mr-15{
  margin-right: 15px
}
.mr-8{
  margin-right: 8px
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-2-per{
  margin-right: 2%
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-05 {
  margin-bottom: .5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-05 {
  margin-left: .5rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-20 {
 margin-left:20px !important;
}
.m-033 {
  margin: .333333rem !important;
}
.m-333 {
  margin: .333333rem !important;
}
.margin-333 {
  margin: .333333rem !important;
}
.mr-16{
  margin-right: 16px !important;
}

.pt-1 {
  padding-top: 1rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-05 {
  padding-top: .5rem !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-05 {
  padding-right: .5rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-05 {
  padding-bottom: .5rem !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-05 {
  padding-left: .5rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 1rem !important;
}
.p-05 {
  padding: .5rem !important;
}
.p-7 {
  padding: 7px !important;
}
.p-20{
  padding: 20px !important;
}
[dir=rtl] {
  .pr-1 {
    padding-left: 1rem;
    padding-right: 0 !important;
  }
  .mr-1 {
    margin-right: 0 !important;
    margin-left: 1rem;
  }
}