// // Third pirty style files
// // Sidebar Scroll
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// // Flag icon
//@import "~flag-icon-css/css/flag-icon.min.css";

// //  star-rating styles (Used in Shop app)
//@import "~css-star-rating/dist/css/star-rating.min.css";
// // Data Table
@import "../../../node_modules/@swimlane/ngx-datatable/index.css";
@import "../../../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../../../node_modules/@swimlane/ngx-datatable/assets/icons.css";
// // Rich Text Editor
//@import "~quill/dist/quill.core.css";
//@import "~quill/dist/quill.snow.css";
// // Drag and Drop
//@import "~dragula/dist/dragula.css";
// // Calendar
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

// // User tour
@import "~hopscotch/dist/css/hopscotch.min.css";
// // Page loader Topbar
@import "../../vendor/pace/pace-theme-min.css";

// // Highlightjs theme
// // @import '~highlight.js/styles/darcula.css';
// // @import '~highlight.js/styles/github.css';
@import "../../../node_modules/highlight.js/styles/github-gist.css";

// Main Variables File
@import "scss/variables";

// // Bootstrap-4
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@import '~handsontable/dist/handsontable.full.css';
@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _themes.scss and remove unnecessary theme files
@import "scss/themes/themes";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";

@import "scss/page-layouts";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";

// // Cropper css
@import "../../../node_modules/cropperjs/dist/cropper.min.css";

// // font-awesome
@import "../../../node_modules/font-awesome/css/font-awesome.css";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

// Skeleton elements to show when data is being fetched
@import '../../../node_modules/skeleton-elements/skeleton-elements';

/* -------------------------------------------------------------------------------------------------*/
/* --------------------------------------- CUSTOM COMMON CSS ---------------------------------------*/
/* -------------------------------------------------------------------------------------------------*/

:root {
  --theme-dark-blue: #29314B;
  --signup2-section-left-bg-img: url('https://ihm-cdn-dev.varuna-sentinels.com/assets/images/bg.png');
  --cancel-image-scaffolding: url('https://ihm-cdn-dev.varuna-sentinels.com/assets/images/cancel.png');
  --home-bg-black: url('https://ihm-cdn-dev.varuna-sentinels.com/assets/images/home-bg-black.png');
  --signup3-trianglify: url('https://ihm-cdn-dev.varuna-sentinels.com/assets/images/backgrounds/trianglify-1.png');
  --signup4-waterfall: url('https://ihm-cdn-dev.varuna-sentinels.com/assets/images/backgrounds/waterfall.jpg');
}

.mat-raised-button {
  border-radius: 0 !important;
}


// Override bootstrap button outline
button:focus {
  outline: none;
}

.card-header {
  color: white;
  background-color: rgba($color: #10174c, $alpha: 1);
}

.card-action-center {
  text-align: center;
}

.rectangle {
  position: absolute;
  z-index: 9;
  background-color: rgba(33, 150, 243, 0.2);
  text-align: center;
  resize: both;
  overflow: hidden;
  border: 1px solid #698296;
  display: flex;
  color: #091a34;
  min-height: 75px;
  min-width: 100px;
}

.div-header {
  position: absolute;
  top: 0;
  width: 100%;
  cursor: move;
  padding: 0.25rem 0.5rem;
}

.move-icon {
  float: left;
}

.close-icon {
  float: right;
  cursor: pointer;
}

.input {
  width: 80%;
  margin: auto auto 0.5rem;
}

.input input {
  width: 100%;
  border-radius: 1rem;
  padding-left: 0.5rem;
  background-color: #c5cbd0;
  color: #091a34;
  font-size: small;
}

.area-resize {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 18px solid rgba(66, 113, 150, 0.769);
  z-index: -1;
}

.croppedImage .img-block {
  width: 196px;
  height: 256px;
  border: 1px solid #acacac;
  margin-left: 26px;
  margin-top: 18px;
  float: left;
  padding: 5px;
}

.croppedImage .title {
  text-align: left;
  border-bottom: 1px solid;
  font-weight: 500;
  color: #10174c;
}

.snack-error {
  z-index: 9999 !important;
}

.marker-light {
  background: #b3b3b3;
  filter: opacity(0.5);
  border: 0.1rem solid grey !important;
}

// .marker {
//   width: 3.5rem;
//   height: 3.5rem;
//   font-size: 1.5rem;
//   position: relative;
//   margin-top: auto;
//   border-radius: 50%;
//   border: 0.1rem solid #fff;
//   color: #fff;
//   z-index: 999 !important;
// }
// .marker div {
//   display: flex;
//   position: relative;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   background-color: inherit;
//   z-index: 1;
// }
// .marker:after {
//   content: "";
//   position: absolute;
//   border-bottom: 2rem solid transparent;
//   border-left: 1rem solid transparent;
//   border-right: 1rem solid transparent;
//   height: 0;
//   width: 0;
//   left: 0.66rem;
//   bottom: 1.95rem;
// }
// .marker:before {
//   content: "";
//   position: absolute;
//   border-bottom: 2.5rem solid #fff;
//   border-left: 1.25rem solid transparent;
//   border-right: 1.25rem solid transparent;
//   height: 0;
//   left: 0.4rem;
//   bottom: 1.75rem;
//   width: 0;
// }
.marker-light:after {
  border-bottom-color: #b3b3b3;
}

.marker-light:before {
  border-bottom-color: grey;
}

// .marker img {
//   height: 30px;
//   width: 30px;
//   top: 10px;
//   left: 11px;
//   position: absolute;
// }

// .mat-drawer-inner-container {
// 	> .nofication-panel {
// 		height: 100%;
// 		> .ng-star-inserted {
// 			height: 100%;
// 		}
// 	}
// 	.card,
//   .mat-drawer-container,
//   #scene {
// 		height: 100%;
//   }
//   .card-body{
//     position: relative;
//     overflow: hidden;
//     padding: 0;
//   }
//   .card-body-content{
//     position: relative;
//     background-color: #fff;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
//   .card{
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     min-width: 0;
//     word-wrap: break-word;
//     background-color: #fff;
//     background-clip: border-box;
//   }

// }

// Custom snack bar panel class for text wrapping.
.custom-snack .mat-simple-snackbar span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zoom-marker-div {
  width: 100%;
  margin: auto auto auto auto;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: white;
}

.zoom-marker-div-style {
  position: absolute;
  cursor: move;
  width: max-content;
  z-index: 0;
}

.zoom-marker-img {
  // position:absolute;
  // left: 0;
  // top:0;
  // z-index:1;
  // box-shadow: 5px 5px 5px #66666688;
  // margin: 32px;
  text-decoration: none;
  border: 1px solid black;
}

.zoom-marker {
  z-index: 2;
  position: absolute;
  cursor: pointer;
}

.zoom-marker img {
  position: absolute;
}

.zoom-marker span {
  position: absolute;
}

.zoom-marker-hover-dialog {
  background-color: #335596;
  display: none;
  position: absolute;
  padding: 10px 10px 0 10px;
  color: #fff;
  border-color: #335596 !important;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  word-break: break-word;
  z-index: 9999;
  min-width: 36% !important;
}

/*Remove Opacity While Integrate Movement History*/
// .zoom-marker-opacity {
//   opacity: 0.4;
//   filter: alpha(opacity=40);
// }

.drag-scroll-content {
  overflow: hidden !important;
  width: 100% !important;
}

// .popover {
//   z-index: 10300 !important;
// }

.popover {
  min-width: 15%;
}


// Remove slider pointer outline-box border
.ng5-slider-span.ng5-slider-pointer {
  outline: none;
}

.marker-light1 {
  background: #4d0670;
}

.marker-light1:before {
  border-bottom-color: #4d0670;
}

.marker-light1:after {
  border-bottom-color: #4d0670;
}

.marker-light2 {
  background: #02549d;
}

.marker-light2:before {
  border-bottom-color: #02549d;
}

.marker-light2:after {
  border-bottom-color: #02549d;
}

.marker-light3 {
  background: #7274ef;
}

.marker-light3:before {
  border-bottom-color: #7274ef;
}

.marker-light3:after {
  border-bottom-color: #7274ef;
}

.marker-light4 {
  background: #d3cc60;
}

.marker-light4:before {
  border-bottom-color: #d3cc60;
}

.marker-light4:after {
  border-bottom-color: #d3cc60;
}

.marker-light5 {
  background: #ffd033;
}

.marker-light5:before {
  border-bottom-color: #ffd033;
}

.marker-light5:after {
  border-bottom-color: #ffd033;
}

.marker-light6 {
  background: #b5037d;
}

.marker-light6:before {
  border-bottom-color: #b5037d;
}

.marker-light6:after {
  border-bottom-color: #b5037d;
}

.marker-light7 {
  background: #1b1301;
}

.marker-light7:before {
  border-bottom-color: #1b1301;
}

.marker-light7:after {
  border-bottom-color: #1b1301;
}

.marker-light8 {
  background: #1b1301;
}

.marker-light8:before {
  border-bottom-color: #1b1301;
}

.marker-light8:after {
  border-bottom-color: #1b1301;
}

.marker-light9 {
  background: #202443;
}

.marker-light9:before {
  border-bottom-color: #202443;
}

.marker-light9:after {
  border-bottom-color: #202443;
}

.marker-light10 {
  background: #1e539e;
}

.marker-light10:before {
  border-bottom-color: #1e539e;
}

.marker-light10:after {
  border-bottom-color: #1e539e;
}

.marker-light11 {
  background: #356860;
}

.marker-light11:before {
  border-bottom-color: #356860;
}

.marker-light11:after {
  border-bottom-color: #356860;
}

.marker-light12 {
  background: #c83e76;
}

.marker-light12:before {
  border-bottom-color: #c83e76;
}

.marker-light12:after {
  border-bottom-color: #c83e76;
}

.marker-light13 {
  background: #3f6897;
}

.marker-light13:before {
  border-bottom-color: #3f6897;
}

.marker-light13:after {
  border-bottom-color: #3f6897;
}

.marker-light14 {
  background: #dc2727;
}

.marker-light14:before {
  border-bottom-color: #dc2727;
}

.marker-light14:after {
  border-bottom-color: #dc2727;
}

.marker-light15 {
  background: #343d0e;
}

.marker-light15:before {
  border-bottom-color: #343d0e;
}

.marker-light15:after {
  border-bottom-color: #343d0e;
}

.pointer:hover {
  cursor: pointer !important;
}

.datatable-body-cell .datatable-body-cell-label {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 0px !important;
}

.color-circle.red {
  background-color: red;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.grey {
  background-color: grey;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.black {
  background-color: black;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.green {
  background-color: green;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.blue {
  background-color: blue;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.yellow {
  background-color: yellow;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.cyan {
  background-color: cyan;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.magenta {
  background-color: magenta;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.brown {
  background-color: brown;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.orange {
  background-color: orange;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.purple {
  background-color: purple;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.color-circle.white {
  background-color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.egret-navy .mat-drawer-side.mat-drawer-end {
  box-shadow: 0px 0px 8px #00000087;
}

.mat-tab-body .ngx-datatable.material .datatable-header .datatable-header-cell {
  padding: 0.9rem 0.5rem;
  //width: 140px !important; // Why this property used please explain.
}

.mat-tab-body .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.9rem 0.5rem;
  //width: 140px !important;
}

// .ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
//   width: 100% !important;
// } -- sonali 05-03-2021
.datatable-row-center.ng-star-inserted {
  width: 100% !important;
}

.ngx-datatable .datatable-body .datatable-scroll {
  width: 100% !important;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus {
  width: 100% !important;
}

// .ngx-datatable .datatable-body .datatable-body-row {
//   width: 100% !important;
// }-- sonali 05-03-2021

.ngx-file-drop__drop-zone {
  margin: 0 4px 8px 4px !important;
  float: left;
  width: 175px;
  height: auto !important;
  border: 0px !important;
  // border: 1px solid #10174c !important;
  // border-radius: 4px !important;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  //   0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

// .ngx-file-drop__content {
//   margin-top: 20px;
// }

mat-sidenav {
  min-width: 15%;
}

.pdfViewer.removePageBorders {
  height: 75.5vh;
}

.ppf-doc .ng2-pdf-viewer-container {
  height: 74vh !important;
  display: block;
}

.ng2-pdf-viewer-container {
  height: 83vh !important;
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .ng2-pdf-viewer-container {
    height: 90vh !important;
  }
}

.card-body.body_height.inner-height .ng2-pdf-viewer-container {
  height: 80vh !important;
}

.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  display: flex !important;
  width: auto;
  justify-content: flex-end;
  float: right;
}

.gray-light {
  color: #000 !important;
  font-weight: 600 !important;
}

.mt-card-h .mat-card-header-text {
  margin: 0;
  width: 100%;
  display: flex;
}

.mat-card-title.mt-card-n {
  width: 100%;
  display: flex;
}

mat-card.mat-card.img-block .mat-card-title.mt-card-n .activated_ship_decks_one {
  width: 72%;
}

mat-card.mat-card.img-block .mat-card-title.mt-card-n .activated_ship_decks_one.restore_btn {
  width: 83%;
}

mat-card.mat-card.img-block .mat-card-title.mt-card-n .activated_ship_decks_one.dd-btn {
  width: 60%;
}

mat-card.mat-card.img-block .mat-card-title.mt-card-n .activated_ship_decks_two {
  width: 100%;
  justify-content: right;
  text-align: right;
}

mat-card.mat-card.img-block .mat-card-title.mt-card-n .activated_ship_decks_two .mat-icon {
  width: auto !important;
  height: auto !important;
  font-size: 16px !important;
}

.egret-navy .mat-icon-button.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}

button.color-icon.mat-icon-button.mat-button-base.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}

mat-card-title.mat-card-title.mt-card-n.s-grid {
  display: grid !important;
}

.asd-box .img-block {
  width: 100%;
  height: auto;
  border: 1px solid #acacac;
  margin: 0 0 10px 0 !important;
  float: left;
  padding: 5px;
}

.text-truncate.font-weight-bold {
  width: 95%;
  font-size: 14px;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat", sans-serif;
}

.croppedImage .title {
  border-bottom: 0px !important;
}

.ngx-file-drop__content {
  display: grid !important;
}

.img-block .mat-card-image {
  width: 100% !important;
  margin: 0 !important;
}

.egret-navy .mat-checkbox-frame {
  border-color: #fff;
}

.ga-btn-save .mat-raised-button {
  width: 36px;
  height: 36px;
  min-width: auto;
  padding: 0 10px;
  border-radius: 50% !important;
}

// .egret-navy .mat-form-field-appearance-legacy .mat-form-field-label {
//   color: #fff;
// }
// .egret-navy .mat-pseudo-checkbox{
//   color: #fff;
// }

// .mat-list-item-content .mat-pseudo-checkbox{
//   color: #fff !important;
// }

.allCheckBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  height: inherit;
}

img.ng-star-inserted {
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin: auto;
  bottom: 50%;
}

.ngx-gallery.ga-plan img.ng-star-inserted {
  width: auto;
}

.mat-card-content {
  padding: 10px !important;
}

.mat-card-content .mat-card-content {
  padding: 0px 5px !important;
}

.ship-fscoll .mat-tab-header {
  background-color: #f8f9fa !important;
  height: 55px;
  z-index: 0;
}

.text-left {
  color: #000 !important;
}

#filter-customizer .sp_filter.active {
  background: #335596 !important;
  color: #fff !important;
  cursor: pointer;
  border-color: #335596;
  border-radius: 0px;
  font-weight: normal !important;
  transition: 0.3s;
}

#filter-customizer .sp_filter:hover {
  background: #335596 !important;
  color: #fff !important;
  cursor: pointer;
  border-color: #335596;
  border-radius: 0px;
  font-weight: normal !important;
  transition: 0.3s;
}

.egret-navy .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.egret-navy .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #335596 !important;
}

.egret-navy .mat-pseudo-checkbox-checked,
.egret-navy .mat-pseudo-checkbox-indeterminate,
.egret-navy .mat-accent .mat-pseudo-checkbox-checked,
.egret-navy .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #335596 !important;
}

.egret-navy .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #575757 !important;
}

.egret-navy .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #000 !important;
}

.egret-navy .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.custom-card-height .mat-expanded .mat-expansion-panel-header {
  height: 36px !important;
  margin-bottom: 1rem;
}

.sp_filter {
  border: 1px solid;
  cursor: pointer;
}

.allCheckBox {
  padding: 0 0px !important;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 0px !important;
}

.mat-selection-list.mat-list-base.ng-pristine.ng-valid.ng-touched {
  outline: none;
  border: none;
  box-shadow: none;
}

.spiner-n .mr-5 {
  margin-right: 0px !important;
}

.egret-navy .mat-raised-button.mat-primary {
  background-color: #10174c !important;
  color: #fff !important;
}

.btn-spinner.ng-star-inserted {
  display: inline-block;
  color: #fff;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  margin-right: 0 !important;
}

button.mat-button-base.mat-primary.button-loading.loading {
  background: #10174c !important;
}

.search-btn .mat-mini-fab .mat-button-wrapper {
  padding: 5px 0 !important;
}

ngx-datatable.ngx-datatable.material.ml-0.mr-0.fixed-header.virtualized.scroll-horz {
  overflow-x: auto;
}

ngx-datatable.ngx-datatable.material.ox-0.ml-0.mr-0.fixed-header.virtualized.scroll-horz,
.ngx-datatable.scroll-horz.ox-0 .datatable-body {
  overflow-x: hidden;
}

h3.popover-title.popover-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popover {
  max-width: 215px !important;
}

datatable-footer.datatable-footer.ng-star-inserted {
  position: fixed;
  bottom: 0;
}

datatable-body.datatable-body {
  margin-bottom: 55px;
}

button-loading .mr-5 {
  margin-right: 0px !important;
}

mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color:focus {
  outline: none;
  border: none;
}

.f-height-card .fleet-tbl.pending_audits .visible{
  height: calc(96vh - 135px);
  // height: 79vh;
  overflow: auto;
}
.f-height-card .fleet-tbl .visible{
  height: calc(96vh - 135px);
  // height: 79vh;
  overflow: auto;
}
.f-height-card .datatable-footer{
  position: initial !important; 
}
.f-height-card datatable-body.datatable-body {
  margin-bottom: 15px;
  width: 100% !important;
}
.f-height-card .datatable-header{
  width: 100% !important;
}
.card-body.cardbodyheight {
  height: 91vh;
  overflow: auto;
}

.app-user-controls {
  padding: 15px 15px !important;
}

.main-footer>div {
  min-height: 51px !important;
}

.sidebar-panel .navigation-hold {
  position: absolute;
  height: calc(100% - 124px) !important;
  width: 100%;
  margin-top: 50px !important;
}

.filter-head {
  height: 55px !important;
}
.handsontable tbody th.ht__highlight, .handsontable thead th.ht__highlight {
  background-color: #10174c;
  color:#fff;
}
.report-preview .mat-form-field-infix {
  width: 95px !important;
  text-align: center !important;
}

.report-preview .mat-form-field-infix:last-child {
  // width: 135px !important;
  text-align: center !important;
}

.fa-ff.ga-btn-zoome.report-preview {
  padding-top: 5px !important;
}

.fa-ff.ga-btn-zoome.report-preview {
  height: 95% !important;
}

.report-preview input::-webkit-outer-spin-button,
.report-preview input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.report-preview input[type="number"] {
  -moz-appearance: textfield;
}

.report-preview input[type="number"] {
  font-size: 16px !important;
}

.fa-ff.report-preview.re-pre .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.6em !important;
}

.doc_preview_container.rep-view .fa-ff.ga-btn-zoome .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0.7em !important;
}

.doc_preview_container.rep-view .fa-ff.report-preview.re-pre .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0.5em !important;
}

.doc_preview_container.rep-view .fa-ff.ga-btn-zoome.report-preview input,
.doc_preview_container.rep-view .fa-ff.report-preview.re-pre.pre-nex input {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.doc_preview_container.rep-view .fa-ff.report-preview.re-pre.pre-nex input {
  padding-bottom: 0px;
  height: 20px;
  margin-top: 5px !important;
}

.doc_preview_container.rep-view .fa-ff.report-preview.re-pre.pre-nex .mat-form-field-underline {
  bottom: 1em;
}

.doc_preview_container.rep-view .fa-ff.report-preview.re-pre.pre-nex {
  margin-top: -2px;
}

.doc_preview_container.rep-view .fa-ff.report-preview.re-pre.pre-nex .mat-form-field-flex {
  margin-top: -1px;
}

.doc_preview_container.rep-view .fa-ff.report-preview.re-pre.pre-nex .mat-form-field {
  margin-top: 2px;
}

.doc_preview_container.report-view-sec.q-rep-view .fa-ff.ga-btn-zoome.report-preview .mat-form-field-underline {
  bottom: 1.5em;
}

.doc_preview_container.report-view-sec.q-rep-view .fa-ff.report-preview.re-pre .mat-form-field-underline {
  bottom: 1.8em;
}

.doc_preview_container.report-view-sec.q-rep-view .fa-ff.ga-btn-zoome.report-preview .ga-input-zoom {
  width: 95px;
}

.sidebar-slate .navigation-hold .branding .app-logo-text {
  line-height: initial;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
  font-weight: 700;
  color: #74c5b5 !important;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.sidebar-slate .navigation-hold .branding .app-logo-text span {
  margin-left: 10px;
}

.mobile-menu {
  display: none;
}

.mat-expansion-panel-header {
  background-color: #f8f9fa !important;
}

.header-close-btn {
  width: 2.5rem;
  height: 100%;
  position: relative;
}

// --------------------------- Marker classes ---------------------------
.marker-opacity {
  opacity: 0.5;
  background: #b3b3b3 !important;
}

.marker-opacity .marker-before,
.marker-opacity .marker-after {
  border-bottom-color: #b3b3b3 !important;
}

.befor-none:before,
.befor-none:after {
  display: none;
}

.marker {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  position: relative;
  margin-top: auto;
  border-radius: 50%;
  border: 0.1rem solid transparent;
  color: #fff;
  z-index: 999 !important;
}

.markers img {
  height: 30px;
  width: 30px;
  top: 10px;
  left: 11px;
  position: absolute;
}

.markers {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: inherit;
  z-index: 1;
}

.marker-before {
  position: absolute;
  border-bottom: 2.5rem solid #fff;
  border-left: 1.25rem solid transparent;
  border-right: 1.25rem solid transparent;
  height: 0;
  left: 0.4rem;
  width: 0;
}

.marker-after {
  position: absolute;
  border-bottom: 2rem solid transparent;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  height: 0;
  width: 0;
  left: 0.66rem;
  bottom: 1.95rem;
  top: -14px;
}

.egret-navy .mat-tab-label.mat-tab-label-active {
  color: #000 !important;
  opacity: 1;
}

.round-btn button {
  border-radius: 50% !important;
  width: 35px;
  padding: 0 6px;
  min-width: 35px;
  height: 35px;
  line-height: initial;
}

.round-btn button.mat-button-base.mat-primary.button-loading.mat-raised-button {
  border-radius: 50% !important;
  width: 35px;
  padding: 0 10px;
  min-width: 35px;
  height: 35px;
}

.round-btn button .mat-icon {
  width: 20px;
  height: auto;
  line-height: normal;
}

.round-btn button.mat-button-base.mat-primary.button-loading.mat-raised-button .mat-icon {
  width: 16px !important;
}

.card-body-fixheight .mat-tab-body-content {
  height: 82.9vh;
  overflow: auto;
}

.card-body-fixheight .ship-pr-sec .mat-tab-body-content {
  overflow-x: hidden !important;
}

.card-body-fixheight .mat-expansion-panel-body {
  padding: 0 0px 10px;
}

.supplier_checkbox .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.mat-tab-label-content .mat-icon {
  margin-right: 5px !important;
}

.fix-hed {
  height: 90.5vh;
  overflow: auto;
}

div#cdk-overlay-2 {
  margin-left: 7px;
}

div#cdk-overlay-3 {
  width: 281.328px !important;
  /*left: 240.328px !important; -- sonali*/
}

.ng-tns-c149-4.ng-trigger.ng-trigger-transformPanel.mat-select-panel.mat-accent {
  min-width: calc(100% + 17px) !important;
}

div#cdk-overlay-4 {
  margin-left: -1px;
}

button.mr-1.mat-icon-button.mat-button-base.mat-primary.ng-star-inserted mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.mat-list-base {
  padding-top: 0px;
}

.egret-navy .mat-drawer-side.mat-drawer-end {
  border-left: none !important;
}

.row.ng-star-inserted .croppedImage.asd-box.col-md-6.col-lg-4.col-xl-4.ng-star-inserted {
  padding: 0 5px !important;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-weight: 800;
  color: #000 !important;
  // color: #10174c !important;
  font-size: 14px !important;
}

.egret-navy .mat-select-disabled .mat-select-value {
  color: #000;
  font-weight: normal !important;
  font-size: 16px;
}

.egret-navy .mat-input-element:disabled,
.mat-form-field-label-wrapper {
  font-weight: normal !important;
  color: #000 !important;
  font-size: 16px !important;
  margin-top: 0 !important;
}

.egret-navy .mat-accent .mat-input-element {
  font-weight: normal;
  color: #000;
  font-size: 16px;
}

.egret-navy .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #000000ba;
}

.egret-navy .mat-select-value {
  font-weight: normal;
  color: #000;
  font-size: 14px;
}

.dbtable .egret-navy .mat-input-element:disabled,
.mat-form-field-label-wrapper {
  font-size: 14px !important;
} 

.dbtable table td {
  vertical-align: top;
}

.dbtable .pointer.m-auto.ng-star-inserted a {
  margin: 0px 3px 5px 3px;
  display: block;
}

.owner-dashboard-tbl.dbtable .pointer.m-auto.ng-star-inserted a {
  display: inline-block;
  width: 20px;
}

.dbtable .row.m-auto.ng-star-inserted .pointer.m-auto.ng-star-inserted {
  // width: 25%;
  text-align: center;
  float: left;
}

.dbtable .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  width: 100% !important;
}

.dbtable .row.m-auto.ng-star-inserted {
  float: left;
  display: block;
  width: 100%;
}

.dbtable.owner-dashboard-tbl .row.m-auto.ng-star-inserted {
  float: none;
  display: inline-block;
  width: auto;
  text-align: center;
}

.ship-deck-fix .ng-star-inserted .ng-star-inserted {
  width: 100%;
}

.egret-navy .mat-header-cell {
  font-weight: normal;
  color: #000;
  font-size: 14px !important;
}

.egret-navy .mat-input-element:disabled,
.mat-form-field-label-wrapper {
  font-weight: 500;
  font-size: 15px;
}

// .dbtable .mat-sort-header-container{
//   justify-content: center;
// }

.egret-navy .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #10174c;
}

.egret-navy .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #10174c6b;
}

.main-content-wrap {
  background: #f6f6f6;
}

.materials-record .datatable-row-center:nth-child(10) {
  min-width: 120px;
}

.ship-fscoll.hgt .mat-tab-group.ww-50.mat-primary .mat-tab-label-content {
  text-transform: uppercase;
}

.card-body.ppf-doc-fix {
  height: 100vh;
  overflow: auto;
}

ngx-file-drop.file-drop-zone {
  width: 100%;
  height: 200px; /* Adjust as needed */
  border: 2px dotted #74c5b5; /* Dotted border with color */
  border-radius: 8px; /* Optional: rounded corners */
  background-color: #fff; /* Optional: background color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.w100 .ngx-file-drop__drop-zone {
  margin: 0px 0 10px 0 !important;
  width: 100%;
  float: left;
  height: auto !important;
  border: 0px solid !important;
  // border-radius: 4px !important;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  //   0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.w100 .ngx-file-drop__drop-zone .ngx-file-drop__content {
  display: flex !important;
  border: 0px solid !important;
}

.w100 .ngx-file-drop__content {
  height: 40px !important;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #335596;
  font-weight: 500;
}

.w100 p {
  margin: 0 0 0 10px;
}

// .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell{
//   width: 70px;
//   min-width: 70px;
// }
// .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell{
//   min-width: 120px;
//   width: 120px;
// }
// ***************** 
// .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6){
//   min-width: 100px;
//   width: 100px !important;
//   max-width: 100px;
// } --change done by sonali on 17-3-2021

// .mat-tab-body .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(6){
//   min-width: 100px;
//   width: 100px !important;
//   max-width: 100px;
// }


// .mat-tab-body .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child{
//   min-width: 70px;
//   width: 70px !important;
//   max-width: 70px;
//   margin: 0 auto;
// }
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner:nth-child(8) {
  width: 130px;
  min-width: 130px;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(8) {
  width: 130px;
  min-width: 130px;
}

// .mat-tab-body .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(8){
//   width: 130px;
//   min-width: 130px;
// }
.ga-p-footer .ga-input-page {
  width: 140px !important;
}

.full-width.hide-line .mat-form-field-underline {
  background-color: transparent !important;
  height: 0;
}

.tab-width .mat-form-field-infix {
  height: 42px;
  width: 100%;
}

.supplier_td .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2),
.supplier_td .datatable-body .datatable-body-row .datatable-body-cell:nth-child(2) {
  width: 130px !important;
  min-width: 130px !important;
}

.supplier_td .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(3),
.supplier_td .datatable-body .datatable-body-row .datatable-body-cell:nth-child(3) {
  width: 130px !important;
  min-width: 130px !important;
}

.supplier_td .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(4),
.supplier_td .datatable-body .datatable-body-row .datatable-body-cell:nth-child(4) {
  width: 130px !important;
  min-width: 130px !important;
}

.supplier_td .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(5),
.supplier_td .datatable-body .datatable-body-row .datatable-body-cell:nth-child(5) {
  width: 160px !important;
  min-width: 160px !important;
}

.supplier_td .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6),
.supplier_td .datatable-body .datatable-body-row .datatable-body-cell:nth-child(6) {
  width: 130px !important;
  min-width: 130px !important;
}

.search-height .mat-form-field-infix {
  height: 45px;
}

/*
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(1) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(11) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    top: 34%;
}
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(10) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 34%;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(12) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 34%;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(13) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 34%;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(14) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 34%;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(15) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 34%;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(16) .datatable-header-cell-template-wrap {
  display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 34%;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}-- sonali/*

@media (max-width:992px){
  body{
    width: 992px;
    overflow: scroll;
  }
  .w-70 {
    height: 100vh !important;
  }
}
@media (max-width: 767px) {
  .sid-br {
    margin-bottom: 10px;
  }
  .report-preview .mat-form-field-infix {
    width: 55px !important;
    text-align: center !important;
  }
  .report-preview.re-pre .mat-form-field-infix {
    width: 90px !important;
    text-align: center !important;
  }
  #filter-customizer {
    min-width: 80% !important;
    max-width: 80% !important;
  }
  .p-1.custom-slider {
    max-width: 100% !important;
  }
  .m-bb-0 {
    margin-bottom: 0px !important;
  }
  .m-t-m15 {
    margin-top: -15px;
  }
  form.right-form {
    position: inherit !important;
    z-index: 999;
    right: 9%;
    width: auto !important;
    top: 0px;
    left: 5%;
  }
  .ship-fscoll {
    height: auto;
    overflow: auto;
  }
  .egret-navy .mat-drawer:not(.mat-drawer-side) .mat-drawer-inner-container{
    overflow: hidden !important;
  }
  .mt-1.nnbtn {
    flex-direction: row;
    box-sizing: border-box;
    display: block !important;
    justify-content: center;
    text-align: center;
  }
  // mat-sidenav-content.mat-drawer-content.mat-sidenav-content {
  //   // margin-right: 0px !important;
  //   // width: 240px;
  // }
  mat-sidenav-container.mat-drawer-container.mat-sidenav-container.res-sc {
    width: 500px;
    overflow: scroll;
  }
  mat-sidenav.mat-drawer.mat-sidenav.ng-tns-c39-27.ng-trigger.ng-trigger-transform.mat-drawer-end.mat-drawer-side.mat-drawer-opened.ng-star-inserted {
    width: 240px !important;
    // position: inherit !important;
    // bottom: 0;
  }
  .w-50 {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .ship-fscoll {
    height: auto !important;
    overflow: auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .card-body.ppf-doc-fix {
    height: 79vh;
    overflow: auto;
  }
  div#images {
    margin-left: 0px !important;
  }
  .card-body.cardbodyheight {
    height: 95vh;
    overflow: auto;
  }
  mat-sidenav.mat-drawer.mat-sidenav.ng-tns-c39-27.ng-trigger.ng-trigger-transform.mat-drawer-end.mat-drawer-side.mat-drawer-opened.ng-star-inserted {
    height: 77vh !important;
  }
  mat-card-content.mat-card-content.ship-fscoll.hgt {
    height: auto !important;
    overflow: hidden;
  }
  .custom-card-height {
    height: 68vh !important;
    max-height: 68vh !important;
    overflow: auto;
  }
  form.right-form {
    position: inherit !important;
    z-index: 999;
    right: 9%;
    width: auto !important;
    top: 0px;
  }
  .mat-tab-label {
    min-width: 100px !important;
  }
  #filter-customizer {
    min-width: 45% !important;
    max-width: 45% !important;
  }
  .m-bb-0 {
    margin-bottom: 0px !important;
  }
  .m-t-m15 {
    margin-top: -15px;
  }
}
@media (max-width: 959px) {
  .mobile-menu {
    display: block;
  }
  .mobile-menu
    button.mat-menu-trigger.topbar-button-right.img-button.mat-icon-button.mat-button-base {
    display: none;
  }
  .mobile-menu {
    position: absolute;
    top: 15px;
    left: -25px;
  }
  .mobile-menu .mat-toolbar.topbar.mat-toolbar-single-row {
    border-radius: 50%;
    height: 56px;
    width: 56px;
  }
  .mobile-menu .mat-toolbar.topbar.mat-toolbar-single-row .mat-icon-button {
    width: 46px;
    height: 56px;
  }
  .mobile-menu mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
    font-size: 22px !important;
  }
  .mat-fab .mat-button-wrapper {
    padding: 16px 0;
    display: inline-block;
    line-height: 24px;
    margin-left: 20px;
    font-size: 10px;
  }
  .handle {
    position: fixed;
    top: 85px;
    left: -25px;
    z-index: 101;
  }
}
@media (min-width: 993px) and (max-width: 1024px) {
  .custom-card-height {
    height: auto !important;
    max-height: 100% !important;
    overflow: auto;
  }
  .card-body.ppf-doc-fix {
    height: 84vh;
    overflow: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .fix-hed {
    height: 94vh;
    overflow: auto;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .fix-hed {
    height: 81.5vh;
    overflow: auto;
  }
  .card-body-fixheight .mat-tab-body-content {
    height: 88vh !important;
    overflow: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .card-body-fixheight .mat-tab-body-content {
    height: 91vh !important;
    overflow: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1400px) {
  .mat-tab-label {
    min-width: 100px !important;
  }
}
@media (min-width: 1281px) and (max-width: 1400px) {
  .custom-card-height[_ngcontent-fgg-c388] {
    height: 60vh;
    max-height: 100%;
    overflow: auto;
  }
  mat-sidenav.mat-drawer.mat-sidenav.ng-tns-c39-27.ng-trigger.ng-trigger-transform.mat-drawer-end.mat-drawer-side.mat-drawer-opened.ng-star-inserted {
    height: 74vh;
  }
}
@media (min-width: 1600px) and (max-width: 1920px) {

  .card-body.ppf-doc-fix {
    height: 86vh;
    overflow: auto;
  }
  .fix-hed {
    height: 94.5vh;
    overflow: auto;
  }
  .pdfViewer.removePageBorders {
    height: 88.5vh !important;
  }
  .ng2-pdf-viewer-container {
    height: 87.5vh !important;
  }
  .ppf-doc .ng2-pdf-viewer-container {
    height: 85vh !important;
    display: block;
  }
  #filter-customizer {
    min-width: 25% !important;
    max-width: 25% !important;
  }
  .f-height-card {
    height: 85vh;
    overflow: auto;
  }
  .card-body.cardbodyheight {
    height: 94.5vh;
    overflow: auto;
  }
  .card-body-fixheight .mat-tab-body-content {
    height: 89vh !important;
    overflow: auto;
  }
}

.ngx-gallery {
  display: inline-block;
  margin-bottom: 20px;
}

.ngx-gallery-preview-icons {
  float: left !important;
  margin-left: 47%;
}

.mat-color-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.masterTitle {
  position: relative;
  overflow: auto;
  width: 100%;
  background: #f8f9fa;
  font-weight: 500;
}

.ngx-gallery-animation-fade .ngx-gallery-image.ngx-gallery-active {
  background-size: contain !important;
  background-position: center;
}
.highlighted{
  background: yellow;
}

.tablecolor .datatable-row-wrapper.ng-star-inserted:nth-child(even) {
  background-color: #f2f2f2;
}





.pdf_icon_tb{
  width: 20px !important;
}

.mat-card-image.img-container.w-100.text-center a.m-auto.text-muted.d-flex.ng-star-inserted img{
  height: 64px;
}
/* Define here the CSS styles applied only to Safari browsers */
.egret-navy .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #000
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) perspective(100px) translateZ(0.001px) !important;
  -ms-transform: translateY(-1.28125em) !important;
}

.tab-width .highlighted {
  background: transparent;
  color: #000 !important;
}

.tab-width .highlighted .datatable-body-cell-label {
  background-color: #FFA500;
  border-radius: 10px;
  text-align: center !important;
}

.tab-width .archived-tbl-po .highlighted .datatable-body-cell-label {
  background-color: transparent !important;
}


// .tab-width .ngx-datatable.material .datatable-header{
//   width: auto !important;
//   display: contents !important;
// }
.tab-width .ngx-datatable .datatable-body .datatable-scroll {
  display: inline-table !important;
}

.tab-width .ngx-datatable.material .datatable-footer {
  border-top: 0px !important;
}


.tab-width .ngx-datatable.material.ml-0.mr-0.tablecolor.fixed-header.virtualized.selectable.checkbox-selection {
  overflow: auto;
}

.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell .mat-input-element {
  caret-color: #10174c;
  font-size: 16px;
}

.tab-width .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
  min-width: 125px !important;
  width: 125px !important;
}

.tab-width.supplier-table .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
  min-width: 50px !important;
  width: 50px !important;
  padding: 0px 10px;
  margin-top: 17px;
  padding-left: 4px !important;
}

.tab-width.supplier-table.shipPoAdmin .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(1) {
  min-width: 70px !important;
  width: 70px !important;
  padding: 5px 10px;
  margin-top: 10px;
}

.tab-width.supplier-table.shipPoAdmin .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
  min-width: 70px !important;
  width: 70px !important;
  padding: 5px 10px;
  margin-top: 7px;
}

.tab-width.supplier-table .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(1) {
  min-width: 50px !important;
  width: 50px !important;
  padding: 0px 10px;
  margin-top: 30px;
}

.tab-width.supplier-table .ngx-datatable.archived-tbl-po .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(1) {
  min-width: 70px !important;
  width: 70px !important;
  padding: 0px 10px;
  margin-top: 15px;
  height: 45px !important;
}

.tab-width.supplier-table .ngx-datatable.archived-tbl-po .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
  min-width: 72px !important;
  width: 72px !important;
  padding: 0px 10px;
  margin-top: 5px;
}

.tab-width.supplier-table .ngx-datatable.archived-tbl-po .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) .mat-icon-button .mat-icon {
  line-height: 18px;
}

// .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child,
// .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child .ng-star-inserted {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

#admin-dashboard-detail-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child,
#admin-dashboard-detail-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child .ng-star-inserted {
  display: block;
}

.ngx-datatable.material.sup-tbl .datatable-body .datatable-body-row .datatable-body-cell:last-child {
  justify-content: flex-start;
}

.fleet-tbl-arch.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.fleet-tbl.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.subfleet-tbl.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.subfleet-tbl-arch.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child {
  justify-content: flex-start;
}

.po_history_tbl.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child {
  justify-content: flex-start;
  align-items: flex-start;
}

.ngx-datatable.material.s-owner-sec .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.ngx-datatable.material.s-owner-sec .datatable-body .datatable-body-row .datatable-body-cell:last-child .ng-star-inserted {
  justify-content: flex-start;
  text-align: left;

}

.ngx-datatable.material.mat-rec .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.ngx-datatable.material.mat-rec .datatable-body .datatable-body-row .datatable-body-cell:last-child .ng-star-inserted {
  display: inline-block !important;
  justify-content: center;
  align-items: center;
}

.ngx-datatable.material .datatable-footer {
  border: none !important
}

.ngx-datatable.material .datatable-body .datatable-row-wrapper:last-child .datatable-body-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.fixed-header.material .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  text-align: left;
}

.ngx-datatable.fixed-header.material.sup-tbl .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  text-align: center;
}

.ngx-datatable.material .datatable-row-wrapper .datatable-body-cell-label button.mr-1.mat-icon-button {
  text-align: left !important;
}

.ngx-datatable.sup-inner-tbl .datatable-body .datatable-body-row .datatable-body-cell:first-child,
.ngx-datatable.fixed-header.sup-inner-tbl .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  width: 70px !important;
}

.ngx-datatable.material.sup-tbl .datatable-body .datatable-body-row .datatable-body-cell:first-child {
  display: flex;
  align-items: baseline;
}



@media (min-width: 1200px) and (max-width: 1600px) {

  .ngx-datatable.mat-rec .datatable-header-inner,
  .ngx-datatable.mat-rec datatable-body-row.datatable-body-row {
    width: 1000px !important;
  }

  .ngx-datatable.mat-rec datatable-header.datatable-header {
    width: 900px !important;
    display: inline-block !important;
  }

  //For Last Column Title
  // .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  //   min-width: 70px;
  //   width: 70px !important;
  //   max-width: 70px;
  // }

  .ngx-datatable.fixed-header.s-owner-sec .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
    min-width: 100px;
    width: 100px !important;
    max-width: 100px;
  }

  .ngx-datatable.mat-rec.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
    min-width: auto;
    width: auto !important;
    max-width: 100%;
  }

  .material-ihm1.ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell {
    min-width: 100px !important;
    width: 100px !important;
  }

  .material-ihm1.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    min-width: 100px !important;
    width: 100px !important;
  }

  .material-ihm1.ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(1) {
    min-width: 70px !important;
    width: 70px !important;
  }

  .material-ihm1.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
    min-width: 70px !important;
    width: 70px !important;
  }

  .material-ihm1.ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:last-child {
    min-width: 125px !important;
    width: 125px !important;
  }

  .material-ihm1.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:last-child {
    min-width: 125px !important;
    width: 125px !important;
  }

  .material-ihm-non.ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:last-child {
    min-width: 115px !important;
    width: 115px !important;
  }

  .material-ihm-non.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:last-child {
    min-width: 115px !important;
    width: 115px !important;
  }

  .material-ihm-non.ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell {
    min-width: 90px !important;
    width: 90px !important;
  }

  .material-ihm-non.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    min-width: 90px !important;
    width: 90px !important;
    text-align: center;
  }

  .material-ihm-non.ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(1) {
    min-width: 100px !important;
    width: 100px !important;
  }

  .material-ihm-non.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1) {
    min-width: 100px !important;
    width: 100px !important;
    text-align: left;
  }
}

.gaplan_preview_container .ga-btn-zoome .mat-raised-button .mat-button-wrapper {
  line-height: 0px !important;
}

.gaplan_preview_container .ga-btn-zoome .mat-raised-button .mat-button-wrapper span,
.doc_preview_container.owner-container .ga-btn-zoome .mat-raised-button .mat-button-wrapper span,
.doc_preview_container.report-view-sec .ga-btn-zoome .mat-raised-button .mat-button-wrapper span {
  vertical-align: revert !important;
}

.doc_preview_container.report-view-sec .fa-ff.ga-btn-zoome.report-preview {
  padding-top: 0px !important;
}

.doc_preview_container .card-body .ng2-pdf-viewer-container {
  height: 82vh !important;
}

.doc_preview_container.owner-container .card-body .ng2-pdf-viewer-container {
  height: 81vh !important;
}

.doc_preview_container.report-view-sec .card-body.body_height.inner-height .ng2-pdf-viewer-container {
  height: 81vh !important;
}

.ngx-datatable.material.fixed-header {
  overflow-x: auto;
}

// @media (min-width: 1200px) and (max-width:1399px){
//   .material-ihm1.ngx-datatable .datatable-header{
//     width: 810px !important;
//   }

//   .material-ihm-non.ngx-datatable .datatable-header{
//     width: 750px !important;
//   }

//  .material-ihm1.ngx-datatable datatable-body-row.datatable-body-row{
//     width: 809px !important;
//   }
//   .material-ihm1.ngx-datatable, .material-ihm-non.ngx-datatable {
//     width: 767px !important;
//     overflow-x: scroll !important;
//   }

//   .material-ihm-non.ngx-datatable {
//     width: 737px !important;
//     overflow-x: scroll !important;
//   }
// }


.tab-width .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(2) {
  min-width: 125px !important;
  width: 125px !important;
}

.tab-width.supplier-table .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(2) {
  min-width: 130px !important;
  width: 130px !important;
}


.tab-width.supplier-table .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell:nth-child(2) {
  min-width: 130px !important;
  width: 130px !important;
  padding-left: 15px;
}

.tab-width.supplier-table .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell .mat-form-field {
  width: 106px !important;
}

.supplier-table .datatable-checkbox input[type='checkbox']:before {
  border: 2px solid #888 !important;
}

.supplier-table .datatable-checkbox input[type='checkbox']:checked:before {
  transform: rotate(-45deg) !important;
  height: 0.5rem !important;
  border-color: #009688 !important;
  border-top-style: none !important;
  border-right-style: none !important;
}

.supplier-table.po-table .datatable-header {
  overflow: visible !important;
  height: 60px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.supplier-table.po-table .datatable-header .datatable-row-center .datatable-header-cell {
  height: 60px !important;
}

.supplier-table.po-table .ngx-datatable .datatable-row-wrapper:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.supplier-table.po-table .ngx-datatable .visible {
  width: 100% !important;
  display: grid;
}

.supplier-table datatable-body-row.datatable-body-row {
  width: 100% !important;
}

.tab-width .ngx-datatable .datatable-header .datatable-header-inner .datatable-row-center .datatable-header-cell {
  min-width: 150px !important;
  width: 150px !important;
  // padding: 5px 10px;
}


.tab-width .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  min-width: 150px !important;
  width: 150px !important;
}

.tab-width.supplier-table.po-table .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  padding-left: 0.7rem;
}

.tab-width.supplier-table.po-table .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
}

.tab-width.supplier-table .ngx-datatable.archived-tbl-po .datatable-body .datatable-body-row .datatable-body-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tab-width.supplier-table.po-table .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:nth-child(2) {
  justify-content: flex-start;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  width: 100%;
}

.bg-nlight {
  background-color: #1E88E5 !important;

}

.bg-blight {
  background-color: #00E5FF !important;
}

.bg-nlight-f {
  background-color: #ab47bc !important;

}

.bg-blight-f {
  background-color: #ff79ff !important
}

.title-text-n {
  font-size: 11px;
  font-weight: 500;
}


.progress {
  height: 5px !important;
  width: 100%;
}





/* ----------------- */
/* TOOLTIP */
/* ----------------- */
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
}

.progress,
.progress-bar {
  overflow: visible !important;
}

/* ----- TOOLTIP RIGHT ----- */
.tooltiptext-right {
  visibility: hidden;
  width: 120px;
  padding: 5px 0;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  background-color: #000000d4;
  opacity: 0;
  transition: opacity 1s;
  /* POSITIONING */
  z-index: 1;
  position: absolute;
  left: 105%;
  top: -5px;
}

.tooltip:hover .tooltiptext-right {
  visibility: visible;
  opacity: 1;
}

/* ----- TOOLTIP LEFT ----- */
.tooltiptext-left {
  visibility: hidden;
  min-width: 120px;
  width: auto;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  background-color: #000000d4;
  /* POSITIONING */
  z-index: 1;
  position: absolute;
  right: 0;
  top: 105%;
  opacity: 0;
  transition: opacity 1s;
  margin-top: 5px;
}

.tooltip:hover .tooltiptext-left {
  visibility: visible;
  opacity: 1;
}

/* ----- TOOLTIP TOP ----- */
.tooltiptext-top {
  min-width: 120px;
  visibility: hidden;
  width: auto;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  background-color: #000000d4;
  opacity: 0;
  transition: opacity 1s;
  /* POSITIONING */
  z-index: 1;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext-top {
  visibility: visible;
  opacity: 1;
}

/* ----- TOOLTIP BOTTOM ----- */
.tooltiptext-bottom {
  min-width: 120px;
  visibility: hidden;
  width: auto;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  background-color: #000000d4;
  opacity: 0;
  transition: opacity 1s;
  /* POSITIONING */
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
  margin-left: 0;
  margin-top: 5px;
}

.tooltip:hover .tooltiptext-bottom {
  visibility: visible;
  opacity: 1;
}

// .progress .bg-warning {
//   font-weight: 700;
//   height: 18px;
//   background-color: #10174c54 !important;
// }
// .progress .bg-success {
//   font-weight: 700;
//   height: 18px;
//   background-color: #10174c !important;
// }
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: #ebebeb;
      -webkit-border-radius: 3px;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background: #8d8d8d;
    }

    .card-body-fixheight .mat-tab-body-content {
      height: 80.5vh;
    }
  }
}

.hmstatus-lbl {
  font-weight: 800;
  color: #000 !important;
  font-size: 14px !important;
}

.ng-trigger.ng-trigger-transformPanel.mat-select-panel.mat-accent {
  margin-top: 35px;
}

.displayNone {
  display: none !important;
}

.egret-navy .mat-raised-button.mat-primary.disablePrevButton {
  color: #ffffff !important;
  background-color: #cccccc !important;
}

.pointerNone {
  pointer-events: none !important;
}

mat-checkbox:focus,
.allCheckBox:focus,
.form-row.scroll_sec.style-3:focus {
  outline: none !important;
  border: none !important;
}


.ngx-gallery.ga-plan .ngx-gallery-thumbnails .ngx-gallery-thumbnail {
  height: 200px !important;
  width: 180px !important;
  border-radius: 10px !important;
}

.ngx-gallery.ga-plan ngx-gallery-thumbnails.mid-align {
  width: 181px;
  margin: 0 auto;
  text-align: center;
}

.ngx-gallery.ga-plan .ngx-gallery-icon i.fa-pencil-square-o {
  color: #10174c;
}

.ngx-gallery.ga-plan .ngx-gallery-icon i.fa-times-circle.close1 {
  color: #F44336;
}

.ngx-gallery-preview-top .ngx-gallery-preview-icons i:hover {
  color: #ffc107;
}

.alert-dismissible .close {
  z-index: 1 !important;
}

.save-report-btn .mat-button-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 32px !important;
}

.save-report-btn .shipowner-text {
  line-height: 1.8;
  margin-left: 3px;
}

.report-close .mat-icon {
  height: 29px !important;
  width: 29px !important;
  font-size: 19px !important;
}

// .mat-drawer-inner-container {
//   overflow: hidden !important;
// }

// Quick fix 
ngx-gallery.report-gallery .ngx-gallery-layout {
  align-items: center;

  ngx-gallery-image {
    height: 78.5vh !important;
    width: 50vw !important;

    .ngx-gallery-active {
      background-size: contain !important;
    }
  }
}

.ship-list-filter.shipaccess {
  overflow: hidden !important;
}

.ship-list-filter.shipaccess .mat-expansion-panel-content {
  overflow-y: auto !important;
  max-height: 567px !important;
  height: 567px !important;
}

.user-table.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  width: 105px !important;
}

.user-table.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:first-child {
  width: auto !important;
}

.manager-table.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:first-child,
.eq-table.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  width: 109px !important;
}

.manager-table.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:first-child,
.eq-table.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:first-child {
  width: 109px !important;
}

.manager-table.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child,
.supplier-table.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child,
.user-table.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  width: 150px !important;
  min-width: 150px;
  max-width: 150px;
}

.manager-table.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child {
  // .supplier-table.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child
  width: 150px !important;
  min-width: 150px;
  max-width: 150px;
}

.sidenav-hold .menuitem-badge {
  border-radius: 11px;
  height: 10px;
  width: 10px;
  padding: 0 !important;
  display: block;
}

// .ps__thumb-y{
//   height: 550px !important;
// } 

.ship-deck-fix .mat-card {
  padding-right: 0 !important;
}

.deck-sidebar.gaplan-list mat-sidenav .mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.card-body-fixheight.po-sec .mat-tab-body-content {
  height: 74.7vh !important;
  overflow: auto;
}

.doc_preview_container.owner-container .mat-form-field-infix {
  padding: 0.10em 0 !important;
  width: 95px !important;
}

.doc_preview_container.owner-container .mat-form-field-infix input {
  font-size: 16px !important;
}

.owner-container .fa-ff.ga-btn-zoome {
  padding-top: 0;
}

.owner-container .ga-p-footer .ga-input-zoom {
  width: 110px;
}

.doc_preview_container.owner-container .fa-ff.ga-btn-zoome .mat-form-field-infix input {
  margin-top: 6px;
}

.doc_preview_container.owner-container .fa-ff.mmt-10 .mat-form-field-infix input {
  margin-bottom: 2px;
}

.doc_preview_container.rep-view .card-footer.ga-p-footer {
  margin-top: -2px;
}

.o_file .mat-form-field-flex {
  align-items: unset !important;
}

.material-icon-bg .mat-button-ripple.mat-ripple {
  height: 24px !important;
  width: 24px !important;
}

.supplier-acc-sec .mat-expansion-panel-body,
.fleet-arc-sec .mat-expansion-panel-body,
.manager-table-arch .mat-expansion-panel-body {
  padding: 0 10px 16px !important;
}

.ship-detail-sec .mat-expansion-indicator {
  line-height: 0 !important;
  margin-top: -5px !important;
}

.manager-table.ar-man-table.ngx-datatable.scroll-horz .datatable-body,
ngx-datatable.ngx-datatable.scroll-horz.fleet-tbl,
ngx-datatable.ngx-datatable.material.ml-0.mr-0.fixed-header.virtualized.scroll-horz.manager-table,
ngx-datatable.ngx-datatable.material.ml-0.mr-0.fixed-header.virtualized.scroll-horz.subfleet-tbl,
ngx-datatable.ngx-datatable.material.ml-0.mr-0.fixed-header.virtualized.scroll-horz.s-owner-sec,
.ngx-datatable.scroll-horz.manager-table .datatable-body,
ngx-datatable.ngx-datatable.material.ml-0.mr-0.fixed-header.virtualized.scroll-horz.eq-table {
  overflow-x: hidden !important;
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .card-body-fixheight .mat-tab-body-content {
    height: 89.5vh;
    overflow: auto;
  }

  .card-body-fixheight.po-sec .mat-tab-body-content {
    height: 84.7vh !important;
    overflow: auto;
  }

  .doc_preview_container .card-body .ng2-pdf-viewer-container {
    height: 89vh !important;
  }
}


@-moz-document url-prefix() {
  .sidebar-panel .navigation-hold {
    height: calc(100% - 100px) !important;
  }
}

@media (min-width: 1890px) and (max-width: 2000px) {
  .doc_preview_container .card-footer.ga-p-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.mat-select-panel-wrap {
  flex-basis: 96% !important;
  max-width: 95% !important;
}

.ga-mat-select {
  max-width: 100%;
}

.ga-option {
  padding: 0 !important;
}

.spinner {
  display: none;
}

.loadingIcon {
  display: none !important;
}

.ng-trigger.ng-trigger-transformPanel.mat-select-panel.mat-accent {
  margin-left: 18px !important;
}

.card-content {
  background-color: #f7f7f7;
}

.mat-action-btn {
  border-radius: 20px;
}

mat-card.mat-card.mat-card-popover {
  padding: 0;
  min-width: 193px !important;

}

mat-card-header.mat-card-header.mat-card-header-popover {
  background-color: #F7F7F7;
  padding: 3px !important;
  height: 36px !important;
}

mat-card-actions.mat-card-actions.mat-card-action-popover {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-around !important;
  margin-bottom: 8px !important;
}

button.mat-action-btn.popover_action.mat-raised-button.mat-button-base {
  border-radius: 5px !important;
  box-shadow: none !important;
  margin: 4px !important;
  padding: 0 !important;
}

button.mat-action-btn.popover_action.cancel.mat-raised-button.mat-button-base {
  border: 2px solid #6c757d !important;
}

button.mat-action-btn.popover_action.cancel.mat-raised-button.mat-button-base:active {
  color: red !important;
  border: 2px solid #6c757d !important;
}

button.mat-action-btn.popover_action.cancel.mat-raised-button.mat-button-base:hover {
  color: white !important;
  background-color: #6c757d;
  border: 2px solid #6c757d !important;
}

.popover_delete {
  line-height: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.popover_delete mat-icon {
  font-size: 20px !important;
}

.popover_delete_inside_table.deck-plan mat-icon {
  font-size: 24px !important;
  color: white !important;

}

button.popover_delete_inside_table.deck-plan.mat-icon-button.mat-button-base {
  font-size: 24px !important;
  line-height: 24px !important;
  background: #F44336;
  margin-right: 5px;
}

.popover_delete_inside_table.restore mat-icon {
  font-size: 24px !important;
  color: white !important;

}

button.popover_delete_inside_table.restore.mat-icon-button.mat-button-base {
  font-size: 24px !important;
  line-height: 24px !important;
  background: #10174c !important;
  margin-right: 5px;
}

button.popover_delete.restore.mat-icon-button.mat-button-base.ng-star-inserted {
  margin-left: 5px;
}

.popover_delete.restore mat-icon,
.popover_delete.yard mat-icon,
.popover_delete.large_icon mat-icon {
  font-size: 24px !important;
}

.mat-card-popover mat-card-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 190px;
}

.popover_delete.deck_check mat-icon {
  vertical-align: baseline;
}

.search-mat-raised-button {
  margin-left: -8px;
}

.dashboard-panel {
  min-width: 1140px;
  overflow-x: auto;
  height: 100%;

  .dashboard-panel-header {
    margin-bottom: 16px;
    height: 40px;
    background: #21273c;
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .panel-close-button {
      color: white;
    }
  }

  .dashboard-panel-outlet-container {
    overflow: auto;
    margin-top: 56px;
  }
}

.mat-expansion-panel-body {
  min-height: 196px;
}

.ship-list-filter .mat-expansion-panel-body {
  min-height: auto;
}

.ship-po-screen-details-panel .mat-expansion-panel-body {
  min-height: 196px;
}

.top-cards-panel .mat-expansion-panel-body {
  min-height: auto;
}

.filter-sec .ship-list-filter .mat-expansion-panel-body {
  min-height: auto;
}

.pos-relative {
  position: relative;
}

.app-admin-container .mat-drawer-backdrop {
  z-index: 4 !important;
}

.table-filters .mat-form-field-infix {
  width: 25% !important;
}

mat-accordion.dashboard-acc .mat-expansion-panel-body {
  padding: 0 11px 16px;
}

.ngx-datatable.material.fixed-header.inner-ngx-tbl {
  overflow-x: hidden !important;
}

.oy-hidden {
  overflow-y: hidden !important;
}

// .pop-tbl mat-menu:before{
//   content:'';
//   width: 0; 
//   height: 0; 
//   border-left: 5px solid transparent;
//   border-right: 5px solid transparent;  
//   border-bottom: 5px solid black;
// }

.mat-menu-content .subMenu .ng-star-inserted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 217px;
  flex-direction: column;
  overflow-x: hidden;
}

.mat-menu-content .subMenu .ng-star-inserted.inner-clr {
  justify-content: flex-start;
  flex-direction: row;
}

.pop-tbl button.mat-button .mat-button-wrapper {
  font-size: 12px !important;
}

div.user-table-menu-container.scrol-style {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.mat-menu-content .subMenu .ng-star-inserted.inner-clr td:first-child {
  width: 82%;
}

.mat-menu-content .subMenu .ng-star-inserted.inner-clr td:first-child span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  display: inline-block;
  font-size: 14px;
}

.mat-menu-content .subMenu .ng-star-inserted.inner-clr td:last-child {
  width: 15%;
}

.mat-menu-content .subMenu .ng-star-inserted.inner-clr td:last-child .mat-icon {
  font-size: 18px;
}

.mat-menu-content {
  padding: 0 !important;
}

// Scrollbar styles
.thin-scrollbar-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

.thin-scrollbar-style::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.thin-scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.thin-scrollbar-style {
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: thin;
}

.div-card {
  font-size: .875rem;
  margin: .5rem;
  overflow: hidden;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 16px;
  background: white;
}

.handsontable .ht_master thead,
.handsontable .ht_master tr th,
.handsontable .ht_clone_left thead {
  visibility: inherit !important;
}

.ht_clone_top {
  visibility: hidden !important;
}

#po-table-container-id .ht_master {
  margin-right: -7px !important;
  margin-left: -8px !important;
  margin-top: 0px !important;
  margin-bottom: 1px;
}

#po-table-container-id .ht_master .wtHolder {
  margin-left: 8px !important;
  height: max-content !important;
  min-height: 100px !important;
  width: auto !important;
}

#po-Table-Container .ngx-datatable.material .datatable-footer {
  display: none !important;
}

#po-Table-Container .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  border: 0.1px solid;
}

#po-Table-Container .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  border: 1px solid;
  background-color: lightgray !important;
}

#po-Table-Container .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  width: 150px !important;
  max-width: 150px !important;
}

#po-Table-Container .ngx-datatable .datatable-body-row:hover {
  border: 0px;
}

#po-Table-Container .ngx-datatable .datatable-body {
  height: 71vh !important;
}

#po-Table-Container .form-row {
  margin-bottom: 10px !important;
}

.mat-tooltip-custom {
  font-size: 12px;
  background-color: #000000 !important;
  opacity: 1;
  color: white;
}

.txtLoadingButton {
  width: 100% !important;
}

/*enchanment+*/

.font-14 {
  font-size: 14px !important;
}

.justify-center {
  justify-content: center !important;
}

.display-flex {
  display: flex !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.height-100vh {
  height: 100vh !important;
}

.padd-0-7 {
  padding: 0 7px !important;
}

.width-35-per {
  width: 35% !important
}

.width-98-per {
  width: 98% !important
}

.width-75 {
  width: 75px !important;
}

.width-60 {
  width: 60px !important
}

.width-45 {
  width: 45px !important
}

.width-55 {
  width: 55px !important;
}

.over-flow-x-auto {
  overflow-x: auto !important;
}

.overflow-auto {
  overflow: auto !important;
}

.max-height-40 {
  max-height: 40vh !important;
}

.text-align-right {
  text-align: right !important;
}

.width-100-per {
  width: 100% !important;
}

.height-96-per {
  height: 96% !important;
}

.font-size-small {
  font-size: small !important;
}

.width-100-vw {
  width: 100vw !important;
}

.width-28-per {
  width: 28% !important;
}

.pos-absolute {
  position: absolute !important;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.display-flex {
  display: flex !important;
}

.height-100-per {
  height: 100% !important;
}

.display-none {
  display: none !important;
}

.max-width-100vw {
  max-width: 100vw !important;
}

.top-auto {
  top: auto !important;
}

.align-self-center {
  align-self: center !important;
}

.display-contents {
  display: contents !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-weight-700 {
  font-weight: 700;
}

.deck-check-pdf {
  height: 100vh;
  overflow: hidden !important;
  cursor: grab !important;
  cursor: -o-grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
}

.border-1-solid {
  border: 1px solid #000 !important;
}

.width-25 {
  width: 25% !important;
}

.product-information {
  width: 100% !important;
  float: left !important;

  p {
    margin-bottom: 5px !important;
    font-size: 14px !important;
  }

  table {
    border-collapse: collapse !important;
    text-align: left !important;
    width: 100% !important;

    tbody {
      tr {
        th {
          border: 1px solid;
          padding: 5px;
          background-color: #ccc;
          text-align: center;
        }

        td {
          border: 1px solid;
          padding: 5px;

          label {
            display: block;
            word-break: break-all;
          }
        }
      }

      tr:nth-child(2) {
        text-align: center;
        border: 1px solid;
        padding: 5px;
        background-color: #ccc;

        th:nth-child(2) {
          width: 150px;
        }
      }
    }
  }
}

.material-information {
  width: 100%;
  float: right;

  p {
    margin-bottom: 5px;
    font-size: 14px;
  }

  p:nth-child(2) {
    padding: 5px;
    text-align: center;
    width: 50%;
    float: left;
  }

  div {
    width: 49%;
    float: right;

    table {
      border-collapse: collapse;
      text-align: left;
      width: 150px;

      tbody {
        tr {
          th:nth-child(2) {
            border: 1px solid;
            padding: 5px;
            background-color: #ccc;
            text-align: center;
            width: 28%;
          }
        }

        tr:nth-child(2) {
          border: 1px solid;
          padding: 5px;
          text-align: center;
          width: 28%;

          td {
            border: 1px solid;
            padding: 5px;

            select {
              width: 100%;
              border: none;
            }
          }
        }
      }
    }

  }
}

.product-information-table-a {
  width: 100%;
  float: left;

  p {
    margin-bottom: 5px;
    font-size: 14px;
  }

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100% !important;

    tbody {
      tr {
        th {
          border: 1px solid;
          padding: 5px;
          background-color: #ccc;
          text-align: center;
        }
      }

      tr:nth-child(2) {
        th:nth-child(1) {
          width: 110px;
          text-align: center;
          border: 1px solid;
          padding: 5px;
          background-color: #ccc;
        }

        th {
          width: 150px;
          text-align: center;
          border: 1px solid;
          padding: 5px;
          background-color: #ccc;
        }
      }

      tr:nth-child(3) {
        td:nth-child(1) {
          border: 1px solid;
          padding: 5px;
          text-align: center;

          p:nth-child(1) {
            margin-bottom: 0px;
            font-size: 14px;
          }

          p:nth-child(2) {
            margin-bottom: 0px;
            font-size: 12px;
          }
        }

        td:nth-child(2) {
          border: 1px solid;
          padding: 5px;
        }

        td:nth-child(3) {
          border: 1px solid;
          padding: 5px;
          text-align: center;
        }

        td {
          border: 1px solid;
          padding: 5px;

          input {
            padding: 7px;
            width: 100%;
            border: none;
            border-bottom: 0px solid #000;
          }

          select {
            padding: 7px;
            width: 100%;
            border: none;
            border-bottom: 0px solid #000;
          }
        }
      }

      tr:nth-child(4) {
        td {
          border: 1px solid;
          padding: 5px;
          text-align: center;

          select {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000
          }

          input {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000
          }
        }
      }

      tr {
        td {
          border: 1px solid;
          padding: 5px;
          text-align: center;

          select {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000
          }

          input {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000
          }
        }
      }
    }

  }

}

.product-information-table-b {
  width: 100%;
  float: left;
  margin-top: 25px;

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    tbody {
      tr {
        th {
          border: 1px solid;
          padding: 5px;
          background-color: #ccc;
          text-align: center;
          width: 8% !important;
        }

        td {
          border: 1px solid;
          padding: 5px;
          text-align: center;

          p:nth-child(1) {
            margin-bottom: 0px;
            font-size: 14px;
          }

          p:nth-child(2) {
            margin-bottom: 0px;
            font-size: 12px;
          }

          select {
            padding: 7px;
            width: 100%;
            border: none;
            border-bottom: 0px solid #000;
          }

          input {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000;
          }
        }

      }
    }
  }
}

.annex {
  width: 100%;
  float: left;
  margin-top: 25px;

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    tbody {
      tr {
        th {
          border: 1px solid;
          padding: 5px;
          background-color: #ccc;
          text-align: center;
          width: 8% !important;
        }

        td {
          border: 1px solid;
          padding: 5px;
          text-align: center;

          p:nth-child(1) {
            margin-bottom: 0px;
            font-size: 14px;
          }

          p:nth-child(2) {
            margin-bottom: 0px;
            font-size: 12px;
          }

          input {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000;
          }

          select {
            padding: 7px;
            width: 99%;
            border: none;
            border-bottom: 0px solid #000;
          }
        }
      }
    }
  }

}

.imp-notice {
  width: 100%;
  float: left;
  margin-top: 15px;

  p {
    font-size: 14px;

    span {
      text-decoration: underline;
    }
  }
}

.upload-file {
  width: 100%;
  float: left;
  margin-top: 15px;
}

.sdoc-form-document {
  border: 1px solid #000 !important;
  padding: 20px;
  float: left;
  margin: 15px;

  div {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      width: 23%;
      float: left;
    }

    div {
      width: 65%;
      float: right;
      padding: 0px 15px;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        background-color: #f1f4ff;
      }

      span {
        float: left;
        padding-top: 14px;
      }

      .object-label {
        float: left;
      }

      .input-div {
        float: right;
        width: 95%;

        input {
          padding: 7px;
          width: 100%;
          border: none;
          border-bottom: 1px solid #000;
          margin-left: 3px;
          background-color: #f1f4ff;
        }

      }
    }

  }

  div:nth-child(9) {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      width: 100% !important;
      float: left !important;
    }
  }

  div:nth-child(10) {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      width: 100% !important;
      float: left !important;
    }
  }

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    tbody {
      tr {
        th {
          border-bottom: 1px solid;
          padding: 5px;
        }

        td {
          border-bottom: 1px solid;
          padding: 5px 0px;

          input {
            padding: 7px;
            width: 94%;
            border: none;
            border-bottom: 0px solid #000;
            background-color: #f1f4ff;
          }

          mat-form-field {
            padding: 0;
            height: 40px;
            margin-top: -20px;

            input {
              margin-bottom: -5px;
              padding: 7px;
              width: 94%;
              border: none;
              background-color: #f1f4ff;

            }

            mat-datepicker-toggle {
              padding: 11px 10px 5px 5px;
              margin: 0px 0px 0px -12px;
              background-color: #f1f4ff;

            }
          }
        }

      }
    }
  }

  .additional-info {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 15px;

    label {
      width: 27%;
      float: left;
    }

    div {
      width: 65%;
      float: right;
      padding: 0px 15px;

      textarea {
        background-color: #f1f4ff;
        padding: 7px;
        width: 100%;
        border-color: #000;
      }
    }
  }

  .signed-place-date {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      float: left;
      padding-left: 15px;
      width: 22%;

    }

    div {
      width: 65%;
      float: right;
      padding: 0px 15px;

      div {
        width: 74%;
        float: right;
        padding: 0px 15px;
      }
    }

    input {
      padding: 7px;
      width: 100%;
      border: none;
      border-bottom: 1px solid #000;
      background-color: #f1f4ff;
    }

    span {
      margin-top: 7px;
      display: block;
    }

    mat-form-field {
      input {
        margin-bottom: -5px;
        padding: 7px;
        width: 94%;
        border: none;
        background-color: #f1f4ff;
      }

      mat-datepicker-toggle {
        padding: 11px 10px 5px 5px;
        margin: 0px 0px 0px -12px;
        background-color: #f1f4ff;
      }
    }
  }

  .signature {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 15px !important;

    span {
      float: left;
      width: 3%;
    }

    label:nth-child(1) {
      width: 45%;
      float: left;

      input {
        padding: 7px;
        width: 94%;
        border: none;
        border-bottom: 1px solid #000;
        float: right;
        margin-bottom: 7px;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
        margin-left: 15px;
      }
    }

    label:nth-child(2) {
      width: 48%;
      float: right;
      padding-right: 15px;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
      }
    }
  }

  .submit-document {
    text-align: center;
    margin-bottom: 15px;
  }

}

.sdoc-form {
  border: 1px solid #000;
  padding: 20px;
  float: left;
  margin: 15px;

  .supplier-declaration {
    div {
      width: 100%;
      margin-bottom: 5px;
      display: inline-block;

      label {
        width: 23%;
        float: left;
      }

      div {
        width: 65%;
        float: right;
        padding: 0px 15px;

        input {
          padding: 7px;
          width: 100%;
          border: none;
          border-bottom: 1px solid #000;
          background-color: #f1f4ff;
        }
      }
    }

    .object-declaration {
      width: 100%;
      margin-bottom: 5px;
      display: inline-block;

      .object-declaration-label {
        width: 23%;
        float: left;
      }

      label {
        width: 23%;
        float: left;
      }

      div {
        width: 65%;
        float: right;
        padding: 0px 15px;

        span {
          float: left;
          padding-top: 14px
        }

        div {
          float: right;
          width: 95%;

          input {
            padding: 7px;
            width: 100%;
            border: none;
            border-bottom: 1px solid #000;
            margin-left: 3px;
            background-color: #f1f4ff
          }
        }
      }
    }

    .declaration {
      label {
        width: 100%;
        float: left;
      }

    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    tbody {
      tr {
        th {
          border-bottom: 1px solid;
          padding: 5px;
        }

        td {
          border-bottom: 1px solid;
          padding: 5px 0px;

          input {
            padding: 7px;
            width: 94%;
            border: none;
            border-bottom: 0px solid #000;
            background-color: #f1f4ff;
          }

          mat-form-field {
            padding: 0;
            height: 40px;
            margin-top: -20px;

            input {
              margin-bottom: -5px;
              padding: 7px;
              width: 94%;
              border: none;
              background-color: #f1f4ff;
            }

            mat-datepicker-toggle {
              padding: 11px 10px 5px 5px;
              margin: 0px 0px 0px -12px;
              background-color: #f1f4ff;
            }
          }

        }

      }
    }
  }

  .additional-information {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 15px;

    label {
      width: 27%;
      float: left;
    }

    div {
      width: 65%;
      float: right;
      padding: 0px 15px;

      textarea {
        background-color: #f1f4ff;
        padding: 7px;
        width: 100%;
        border-color: #000;
      }
    }
  }

  .signed-for {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      width: 27%;
      float: left;
      padding-left: 15px;
    }

    div {
      width: 65%;
      float: right;
      padding: 0px 15px;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        background-color: #f1f4ff;
      }
    }
  }

  .place-of-issue {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
      }

      .width-25vw {
        width: 25vw;
      }
    }

    div {
      width: 74%;
      float: right;
      padding: 0px 15px;
    }
  }

  .date-of-issue {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;

    label {
      width: 24%;
      float: left;

      mat-form-field {
        padding: 0;
        height: 40px;
        margin-top: -20px;

        input {
          margin-bottom: -5px;
          padding: 7px;
          width: 94%;
          border: none;
          background-color: #f1f4ff;
        }

        mat-datepicker-toggle {
          padding: 11px 10px 5px 5px;
          margin: 0px 0px 0px -12px;
          background-color: #f1f4ff;
        }
      }

      span {
        margin-top: 7px;
        display: block;
      }
    }

  }

  .name-signature {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 15px;

    span {
      float: left;
      width: 3%;
    }

    .name {
      width: 45%;
      float: left;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        float: right;
        margin-bottom: 7px;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
        margin-left: 15px;
      }

    }

    .signature {
      width: 48%;
      float: right;
      padding-right: 15px;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
      }
    }
  }

  .name-signature-md {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 15px;

    .name {
      width: 45%;
      float: left;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        float: right;
        margin-bottom: 7px;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
        margin-left: 15px;
      }

    }

    .signature {
      width: 48%;
      float: right;
      padding-right: 15px;

      input {
        padding: 7px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        background-color: #f1f4ff;
      }

      span {
        margin-top: 7px;
        display: block;
      }
    }
  }
}

.submit-document {
  text-align: center;
  margin-bottom: 15px;
}

.mdoc-form {
  border: 1px solid #000;
  padding: 20px;
  font-size: 12px;
  float: left;
  margin: 15px;

  .material-declaration-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .date-of-declaration {
    p {
      margin-bottom: 5px;
      font-size: 14px;
    }

    table {
      border-collapse: collapse;
      text-align: left;
      width: 100%;

      tbody {
        tr {
          th {
            border: 1px solid;
            padding: 5px;
            background-color: #ccc;
            text-align: center;
            width: 40%;
          }

          td {
            border: 1px solid;
            padding: 5px;
          }
        }
      }
    }
  }

  .md-other-supplier {
    width: 100%;
    float: left;

    .md-other {
      width: 50%;
      float: left;

      p:nth-child(1) {
        margin-bottom: 5px;
        font-size: 14px;
      }

      table {
        border-collapse: collapse;
        text-align: left;
        width: 100%;

        tbody {
          tr {
            th {
              border: 1px solid;
              padding: 5px;
              background-color: #ccc;
              text-align: center;
              width: 28%;
            }

            td {
              border: 1px solid;
              padding: 5px;
            }
          }
        }

      }
    }

    .supplier {
      width: 49%;
      float: right;

      p {
        margin-bottom: 5px;
      }

      table {
        border-collapse: collapse;
        text-align: left;
        width: 100%;

        tbody {
          tr {
            th {
              border: 1px solid;
              padding: 5px;
              background-color: #ccc;
              text-align: center;
              width: 28%;
            }

            td {
              border: 1px solid;
              padding: 5px;
            }
          }
        }

      }
    }
  }
}

.background-white {
  background-color: white;
}

.width-inherit {
  width: inherit;
}

.font-size-1em {
  font-size: 1.5em;
}

.add-btn mat-icon {
  font-weight: bold !important;
}

.height-85-vh {
  height: 85vh;
}

.slider-sec {
  margin: 8px;
  border-radius: 15px;
  padding: 15px 15px 30px 15px;
  margin-bottom: 16px;
  background: #fff;
}

.flex-direction-row {
  flex-direction: row !important;
}

.apply-box-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.period-slider {
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  margin-left: 8px;
}

.years-input mat-form-field {
  padding-top: 2px;
  width: 72px;
}

.years-input mat-form-field {
  padding-top: 2px;
  width: 72px;
}

.auto-complete-loader {
  padding-left: 4px;
}

.ch-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  height: 60%;
  text-align: center;
  margin-left: 5px;
}

.chart-box {
  height: 65vh;
  width: 98%;
}

.po-count-icon {
  background-color: #ffc107;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  padding: 0;
  text-align: center;
}

.po-count-icon .mat-icon {
  padding: 16px 0;
  display: inline-block;
  line-height: 24px;
}

.grplevel-1 {
  padding-left: 2% !important;
}

.grplevel-2 {
  padding-left: 5% !important;
}

.grplevel-3 {
  padding-left: 7% !important;
}

.pia-chart-o {
  overflow: visible;
  height: 20vh;
  width: 90%;
  margin: 0 auto;
}

.text-tb {
  position: relative;
  overflow: auto;
  width: 100%;
  background: #f8f9fa;
  font-weight: 500;
}

p.vss-text {
  margin: 6px;
  text-align: center;
}

p.vss-text-aa {
  position: absolute;
  top: 33%;
  right: 0;
  left: 0;
  margin: auto;
  width: 70px;
  text-align: center;
  transform: translate(0%, 0%);
}

.re-p-0 {
  padding: 7px 10px 7px 15px !important;
}

.m-r-10 {
  margin-right: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.total_text {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 38%;
  font-size: 12px;
  z-index: 999;
}

.ihm-part-section {
  margin: 12px 0;
}

.ihm-part-margin {
  margin: 0 12px;
}

.mathight {
  background: #fff;
}

.audit-screen-container {
  width: 100%;
  height: 100%;
  padding: 16px 24px 24px;
}

.audit-screen-title {
  font-size: 32px;
  margin-bottom: 32px;
}

.flex-container-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.save-button {
  color: white;
  background: #339900;
}

.flex-container {
  display: flex;
}

.summary-text {
  margin-right: 16px;
}

.discard-button {
  margin-left: 16px;
  color: white;
  background: #cc3300;
}

a.login-hover:hover {
  text-decoration: underline !important;
}

.items-center {
  align-items: center;
}

.div-flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.not-found-sec {
  height: 285px;
  position: relative;
  overflow: hidden;
  width: 645px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nodata-text {
  /* position: absolute;
  bottom: 0; */
  font-size: 30px;
  font-weight: bold;
}

span.popover_delete_inside_table.deck-plan {
  font-size: 24px !important;
  line-height: 24px !important;
  background: #F44336;
  margin-right: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

span.popover_delete_inside_table.deck-plan mat-icon {
  padding-top: 9px;
  padding-right: 32px;
}

span.popover_delete_inside_table.restore.deck-plan {
  font-size: 24px !important;
  line-height: 24px !important;
  background: #F44336;
  margin-right: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

span.popover_delete_inside_table.restore.deck-plan mat-icon {
  padding-top: 9px;
  padding-right: 32px;
}

// update on 18-01-22 
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell svg {
  overflow: hidden;
  vertical-align: top !important;
}

.width-65 {
  width: 65px !important;
}

.tab-width.supplier-table.po-table .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell:last-child {
  padding-left: 50px !important;
}

.readOnlyCell {
  color: #C6C6C6;
  cursor: not-allowed;
}

#new-po-table-container-id .wtHolder {
  width: 100% !important;
}

#existing-po-table-container-id .wtHolder {
  width: 100% !important;
}


// 23-05-2022
.po-table-container .ht_master .wtHolder{
  overflow: hidden;
}
.po-table-container .ht_master {
overflow: initial !important;
}
.po-table-container .ht_master .wtHolder {
overflow: initial !important;
}
.new_input .mat-form-field-label {
  font-weight: 600;
  font-size: 13px !important;
}
.new_input  .mat-input-element {
  font-size: 14px !important;
}
.copyright span {
    color: #fff;
    font-size: 9px;
    border-top: 1px solid #404555;
    padding-top: 3px;
    display: block;
    margin-top: 5px;
}
app-footer {
  display: none;
}

.set_height{
  height: calc(100vh - 153px);
}
.set_height mat-card-content.mat-card-content.p-0, .set_height mat-card-content.mat-card-content.p-0 form{
  height: 100%;
}
.title_btn .mat-mini-fab{
  width: 30px;
  height: 30px;
}
.title_btn .col-md-6.text-left{
  display: flex;
  align-items: center;
}
.title_btn .material-icons{
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.title_btn .mat-button-wrapper{
  padding: 0 !important;
}
.po-table-container table th {
  background-color: #2a324c;
  color: #fff;
}



mat-card-content.mat-card-content.p-0.f-height-card datatable-body-cell.datatable-body-cell:last-child .datatable-body-cell-label{
  display: flex;
  align-items: flex-start;
}

mat-card-content.mat-card-content.p-0.f-height-card datatable-body-cell.datatable-body-cell:last-child .datatable-body-cell-label .mat-icon-button{
  height: auto;
  line-height: inherit;
}

mat-card-content.mat-card-content.p-0.f-height-card .datatable-body-cell .datatable-body-cell-label{
  margin-left: -1px !important;
}
mat-card-content.mat-card-content.p-0.f-height-card .mat-icon{
  height: 18px;
  width: 18px;
  font-size: 18px;
  line-height: 18px;
}


.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  display: inline-flex;
}


.purchase_orders .mat-card-content .fleet-tbl .visible{
  // height: calc(100vh - 180px);
  height: calc(100vh - 320px);
}
.purchase_orders {
  padding: 0px;
  margin-top: 25px;
}
// .pending_audits .datatable-body{
//   height: calc(100vh - 270px) !important;
// }
// .user-table .datatable-body{
//   height: calc(100vh - 270px) !important;
// }
.user-table .datatable-header .datatable-header-cell{
  padding: 0.9rem !important;
}
.user-table .datatable-body .popover_delete,
.user-table .datatable-body button.mat-focus-indicator{
    width: 30px !important;
    height: 24px !important;
    line-height: 24px !important;
    margin-left: 0 !important;
}
.user_table_box {
  height: calc(100vh - 75px) !important;
  overflow-y: scroll;
}
div#new-po-table-container-id, div#existing-po-table-container-id {
  overflow: auto;
}
div#existing-po-table-container-id {
  width: 98%;
}
// Scrollbar styles
.f-height-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

.f-height-card::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.f-height-card::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.f-height-card {
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: 6px;
}

.f-height-card::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
.f-height-card::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
.f-height-card::-webkit-scrollbar-button {
  display: none;
}


.f-height-card .datatable-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

.f-height-card .datatable-body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.f-height-card .datatable-body::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.f-height-card .datatable-body{
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: 6px;
}

.f-height-card .datatable-body::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
.f-height-card .datatable-body::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
.f-height-card .datatable-body::-webkit-scrollbar-button {
  display: none;
}







.f-height-card .fleet-tbl .visible::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

.f-height-card .fleet-tbl .visible::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.f-height-card .fleet-tbl .visible:-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.f-height-card .fleet-tbl .visible{
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: 6px;
}

.f-height-card .fleet-tbl .visible::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
.f-height-card .fleet-tbl .visible::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
.f-height-card .fleet-tbl .visible::-webkit-scrollbar-button {
  display: none;
}

.user_table_box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}
.user_table_box::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
.user_table_box::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.user_table_box {
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: 6px;
}

.user_table_box::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
.user_table_box::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
.user_table_box::-webkit-scrollbar-button {
  display: none;
}




.user-table .datatable-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

.user-table .datatable-body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.user-table .datatable-body::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.user-table .datatable-body {
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: 6px;
}

.user-table .datatable-body::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
.user-table .datatable-body::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
.user-table .datatable-body::-webkit-scrollbar-button {
  display: none;
}



.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body {
  scrollbar-color: #335595 #F5F5F5;
  scrollbar-width: 6px;
}

.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
.purchase_orders .mat-card-content .fleet-tbl .visible .datatable-body::-webkit-scrollbar-button {
  display: none;
}




::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}

::-webkit-scrollbar {
  height: 6px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
  background-color: #335595;
  border-radius: 3px;
}
::-webkit-scrollbar-button {
  display: none;
}


/* Target the textarea's scrollbar */
textarea::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Style the scrollbar track */
textarea::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  // border-radius: 10px; /* Optional: Rounded corners for the track */
}

/* Style the scrollbar thumb */
textarea::-webkit-scrollbar-thumb {
  background: #74c5b5; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

/* Style the scrollbar thumb on hover */
textarea::-webkit-scrollbar-thumb:hover {
  background: #548d81; /* Color of the scrollbar thumb when hovered */
}