$mat-primary: map-get($material-colors, dark-blue);
$mat-accent: map-get($material-colors, amber);

$egret-primary: mat-palette($mat-primary);
$egret-accent:  mat-palette($mat-accent);

// custom theme palettes are passed into a theme function
$egret-theme: mat-light-theme($egret-primary, $egret-accent);

$primary-dark: darken( map-get($mat-primary, 500), 8% );

// Material theme is wrapped by a parent class
.egret-navy {
    @include angular-material-theme($egret-theme);

    .mat-bg-primary,
    .topbar:not(.topbar-white),
    .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
    .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
    .fileupload-drop-zone.dz-file-over,
    .toolbar-avatar.online > .status-dot,
    .cal-open-day-events,
    div.hopscotch-bubble {
        background: map-get($mat-primary, 500) !important;
        color: #ffffff !important;
    }
    .mat-color-primary,
    .list-item-active,
    .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
    .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
    .sidenav-hold .sidenav li.open > a > span {
        color: map-get($mat-primary, 500) !important;
    }
    .sidebar-panel .mat-nav-list .mat-list-item {
        color: rgba(0, 0, 0, 0.94)
    }
    .topnav ul.menu > li > div.open > a,
    .topnav ul.menu > li > div.open > div,
    .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
    .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
    .list-item-active {
        border-color: map-get($mat-primary, 500) !important;
    }
    // .sidebar-panel {
    //     background: url('../../../../assets/images/sidebar-bg.jpg') no-repeat;
    // }
    .sidebar-compact ul.submenu,
    .default-bg {
        background: #ffffff !important;
    }
    .default-light-bg {
      background: #fafafa !important;
    }
    /* White Background */ 
    .topbar-white {
        background: #ffffff;
        // .mat-icon {
        //     color: rgba(0, 0, 0, 0.87);
        // }
        .search-bar .top-search-form {
            box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);
        }
    }

    /* Hopscotch Tour */ 
    div.hopscotch-bubble {
        border-color: $primary-dark;
    }
    /* up arrow  */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
        border-bottom: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
        border-bottom: 17px solid map-get($mat-primary, 500);
        top: -16px;
    }
    /* right arrow */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
        border-left: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
        border-left: 17px solid map-get($mat-primary, 500);
        left: -1px;
    }
    /* bottom arrow */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
        border-top: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
        border-top: 17px solid map-get($mat-primary, 500);
        top: -18px;
    }
    /* Left Arrow */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
        border-right: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
        left: 1px;
        border-right: 17px solid map-get($mat-primary, 500);
    }

    .mat-bg-accent,
    div.hopscotch-bubble .hopscotch-bubble-number,
    div.hopscotch-bubble .hopscotch-nav-button.next:hover,
    div.hopscotch-bubble .hopscotch-nav-button.next {
        background-color: map-get($mat-accent, 500);
        color: black;
    }
    .mat-bg-warn {
        background-color: #f44336;
        color: white;
    }
    .mat-color-accent {
        color: map-get($mat-accent, 500);
    }
    .mat-color-warn {
        color: #f44336;
    }
    .mat-color-default {
        color: rgba(0, 0, 0, 0.87);
    }

    .fill-color-accent {
        color: map-get($mat-accent, 500);
    }

    .fill-color-warn {
        color: #f44336;
    }
    @media (max-width: 959px) {
      .default-bg-mobile {
        background: #ffffff;
      }
    }
}